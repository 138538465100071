import './InsightsButton.scss';
import { Grid, IconButton } from '@mui/material';
import { useAppContext } from '../../AppContext/App.context';
import Button from '../Button/Button';
import ToolTipOnText from '../Tooltip/Tooltip';
import * as actions from '../../AppContext/actions/actions';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useClickOutside } from '../../hooks/clickOutside';
import { useEffect, useRef, useState } from 'react';
import InsightsEndPoints from './InsightsEndPoints';
import useApiService from '../../services/api.service';
import useToast from '../Toast/hooks/useToast';
import useLoader from '../../hooks/useLoader';
import BarGraph from '../../containers/Reports/Dashboard/StackedChart';
import insightAnswers from '../../utils/InsightsAnswers';
import LineChart from '../../containers/Attribution/PPC/LineChart';
import { useTheme } from '@mui/styles';
import useLocalStorage from '../../utils/localStorage';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { getBarGraphOptions } from '../../containers/Reports/Dashboard/reports/reportData';
import formatNumber from '../../utils/metricSuffix';
const InsightsDrawer = (props: any) => {
	const APIService = useApiService();
	const toaster: any = useToast();
	const loader: any = useLoader();
	const [appData, dispatch]: any = useAppContext();
	const insightsRef: any = useRef();
	const [questionText, setQuestionText] = useState('');
	const [nodataCheck, setNodataCheck] = useState(true);
	const [series, setSeries]: any = useState([]);
	const [seriesLabels, setSeriesLabels]: any = useState([]);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [questionResponse, setQuestionResponse]: any = useState();
	const [questionId, setQuestionId]: any = useState();
	const [lineData, setLineData]: any = useState([]);
	const [graphName, setGraphName] = useState('');
	const theme: any = useTheme();
	const [barGraphOptions, setBarGraphOptions] = useState<any>({});
	const [showGraph, setShowGraph] = useState(false);
	const [showAnswer, setShowAnswer] = useState(false);
	const [multilineChart, setMultilineChart] = useState(false);
	const [isGraphLoading, setGraphLoading] = useState(true);
	const [showLGraph, setShowLine] = useState(false);
	const addMonths = (date: any, months: any) => {
		date.setMonth(date.getMonth() + months);
		return date;
	};
	const [urlsGraphs, setGraphUrls]: any[] = useState([]);
	const options: any = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	};
	//setDate()
	useEffect(() => {
		let el: any = document.getElementsByTagName('body');
		if (el && el.length) el[0].style.overflowX = 'hidden';
		setStartDate(
			addMonths(new Date(), -6).toLocaleDateString('en-US', options)
		);
		const today = new Date();
		setEndDate(today.toLocaleDateString('en-US', options));
	}, []);
	const getInsightsAnswer = async (questionId: any) => {
		setNodataCheck(true);
		setSeries([]);
		setSeriesLabels([]);
		setLineData([]);
		setShowLine(false);
		setQuestionResponse(null);
		setGraphName('');
		setShowGraph(false);
		setBarGraphOptions(null);
		setGraphUrls([]);
		setMultilineChart(false);
		setGraphLoading(true);
		useEffect(() => {
			const storedThemeOption = localStorage.getItem('themeOption');
			if (storedThemeOption === 'custom') {
				setThemeOption(storedThemeOption);
			}
		}, []);
		const [themeOption, setThemeOption] = useState('default');
		const LSFilters = useLocalStorage.getItem('filters');
		let attribution_model = 'linear_model';
		if (LSFilters?.insights) {
			attribution_model = LSFilters?.insights?.attribution_model;
		}
		const request = {
			attribution_model: attribution_model,
			question_id: questionId,
		};
		const reqData = {
			request: request,
		};
		let horizontalBarOptions: any = {
			...getBarGraphOptions(theme),
			chart: {
				type: 'bar',
				height: 200,
				plotBackgroundColor:
					theme.palette.mode === 'dark'
						? '#2f2f2f'
						: theme.palette.background.light,
				backgroundColor:
					theme.palette.mode === 'dark'
						? '#2f2f2f'
						: theme.palette.background.light,
				borderColor:
					theme.palette.mode === 'dark'
						? '#2f2f2f'
						: theme.palette.background.light,
			},
			xAxis: {
				categories: [],
				className: 'drill_down_bar_Graph',
				title: {
					text: null,
					style: {
						color: theme.palette.text.primary,
					},
				},
				labels: {
					style: {
						color: theme.palette.text.primary,
					},
				},
			},
			yAxis: {
				labels: {
					useHTML: true,
					format: '{text}',
					// questionId == 5 || questionId == 6 || questionId == 13
					// 	? '${text}'
					// 	: '{text}',
				},
			},

			tooltip: {
				//  @ts-ignore
				formatter: (point: any) =>
					`<div class=tooltip>` +
					`<div> ${point?.chart?.hoverPoint?.category}: </div>` +
					`<div> <b>${
						questionId == 15 ? '%' : showHashSymbol(questionId) ? '#' : '$'
					} ${formatNumber(point?.chart?.hoverPoint?.y)} </b></div>` +
					`</div>`,
				outside: true,
				// pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
			},
			plotOptions: {
				series: {
					dataLabels: {
						enabled: false,
					},
				},
			},

			series: [
				{
					color: themeOption == 'custom' ? theme.palette.graph[0] : '#976EFA',
					width: 20,

					data: [],
					tooltip: {
						pointFormat:
							questionId == 15
								? '<b>%{point.y:.2f}</b>'
								: showHashSymbol(questionId)
								? '<b>#{point.y:.2f}</b>'
								: '<b>${point.y:.2f}</b>',
						// questionId == 5 || questionId == 6 || questionId == 13
						// 	? '<b>${point.y:.2f}</b>'
						// 	: '<b>{point.y:.2f}</b>',
						valueSuffix: '',
					},
				},
			],
		};
		loader.showLoader();
		return await APIService.post(
			InsightsEndPoints.getInsightsAnswer(reqData),
			true
		)
			.then((res: any) => {
				loader.hideLoader();
				setQuestionResponse(res.data);
				setGraphName(res?.data.graph_details?.graph_name);
				if (questionId == 14) {
					setGraphUrls([
						{
							name: "Top URL's by Closed Won",
							series: [
								{
									data: res.data.close_won.count || [],
									showInLegend: false,
									//name:'years'
								},
							],
							labels: res.data.close_won?.urls || [],
							condition: res.data.close_won.count.length ? true : false,
							yAxisTitle: 'Closed Won',
						},
						{
							name: "Top URL's by Leads",
							series: [
								{
									data: res.data.total_leads.count || [],
									showInLegend: false,
									//name:'years'
								},
							],
							labels: res.data.total_leads?.urls || [],
							condition: res.data.total_leads.count.length ? true : false,
							yAxisTitle: 'Total Leads',
						},
						{
							name: "Top URL's by Opportunity",
							series: [
								{
									data: res.data.total_opprs.count || [],
									showInLegend: false,
									//name:'years'
								},
							],
							labels: res.data.total_opprs?.urls || [],
							condition: res.data.total_opprs.count.length ? true : false,
							yAxisTitle: 'Total Opportunity',
						},
					]);
				} else if (
					(questionId < 10 || questionId == 13 || questionId == 15) &&
					res.data &&
					res.data.final_graph_data &&
					res.data.final_graph_data?.values?.length
				) {
					horizontalBarOptions.chart.height =
						res.data.final_graph_data?.months?.length >= 5 ? null : 200;
					setNodataCheck(false);
					setSeriesLabels(res.data.final_graph_data?.months);
					let seriesData: any[] = [
						{
							data: res.data.final_graph_data?.values,
							showInLegend: false,
							//name:'years'
						},
					];
					let lineGraphData = res.data.final_graph_data?.values;
					setMultilineChart(false);
					if (questionId == 7 || questionId == 8 || questionId == 9) {
						lineGraphData = [
							{
								data: res.data.final_graph_data?.values,
								showInLegend: true,
								name: 'Attemped Sales Touches',
								tooltip: {
									pointFormat: showHashSymbol(questionId)
										? '<b>#{point.y:.2f}</b>'
										: '<b>${point.y:.2f}</b>',
								},
							},

							{
								data: res.data.final_graph_data?.completed,
								showInLegend: true,
								name: 'Completed Sales Touches',
								tooltip: {
									pointFormat: showHashSymbol(questionId)
										? '<b>#{point.y:.2f}</b>'
										: '<b>${point.y:.2f}</b>',
								},
							},
							{
								data: res.data.final_graph_data?.close_won,
								showInLegend: true,
								name: 'Closed Won',
								tooltip: {
									pointFormat: showHashSymbol(questionId)
										? '<b>#{point.y:.2f}</b>'
										: '<b>${point.y:.2f}</b>',
								},
							},
						];
						setMultilineChart(true);
					}
					horizontalBarOptions.series[0].data =
						res.data.final_graph_data.values || [];
					horizontalBarOptions.xAxis.categories =
						res.data.final_graph_data?.months || [];
					setBarGraphOptions(horizontalBarOptions);
					setShowGraph(true);
					setSeries(seriesData);
					setLineData(lineGraphData);
					setShowLine(true);
				} else if (
					questionId >= 10 &&
					res.data &&
					res.data.final_graph_data &&
					res.data.final_graph_data?.target?.length
				) {
					setGraphLoading(false);
					let deficit: any = [];
					let surplus: any = [];
					let achieved: any = [];
					res.data.final_graph_data?.achieved.forEach((el: any, index: any) => {
						let diff = res.data.final_graph_data?.target[index] - el;
						if (diff > 0) {
							deficit.push(diff);
							surplus.push(0);
							achieved.push(res.data.final_graph_data?.target[index] - diff);
						} else {
							deficit.push(0);
							surplus.push(Math.abs(diff));
							achieved.push(res.data.final_graph_data?.target[index]);
						}
					});
					const seriesData: any = [
						{
							data: surplus,
							name: 'Surplus',
						},
						{
							data: deficit,
							name: 'Deficit',
						},
						{
							data: achieved,
							name: 'Achieved',
						},
						{
							marker: {
								symbol: 'c-rect',
								lineWidth: 3,
								lineColor: 'black',
								radius: 10,
							},
							pointWidth: 10,
							type: 'scatter',
							data: res.data.final_graph_data?.target,
							name: 'Target',
							// pointPadding: 0,
							// groupPadding: 0,
							// pointPlacement: 'start',
						},
					];
					setSeriesLabels(res.data.final_graph_data?.data);
					setSeries(seriesData);
				} else {
					setNodataCheck(true);
					setSeries([]);
					setLineData([]);
					setShowLine(false);
					setSeriesLabels([]);
					setShowGraph(false);
					setBarGraphOptions(null);
					setGraphLoading(false);
				}
			})
			.catch((err: any) => {
				loader.hideLoader();
				setMultilineChart(false);
				setSeries([]);
				setLineData([]);
				setShowLine(false);
				setSeriesLabels([]);
				setGraphName('');
				setNodataCheck(true);
				setShowGraph(false);
				setBarGraphOptions(null);
				setGraphLoading(false);
				setGraphUrls([]);
				toaster.addToast({
					message: err.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
			});
	};
	useClickOutside(insightsRef, () => {
		setTimeout(() => {
			props?.sidebarEl?.classList.remove('Sidebar-backdrop');
			let el: any = document.getElementsByTagName('body');
			if (el && el.length) el[0].style.overflowX = 'unset';
		}, 0);
		props.stateChanger(false);
		props.viewStateChange(false);
		setShowAnswer(false);
		localStorage.setItem('isInsightsOpened', 'false');
	});

	const showLineGraph = () => {
		let showLine = false;
		if (
			questionId == 2 ||
			questionId == 3 ||
			questionId == 4 ||
			questionId == 7 ||
			questionId == 8 ||
			questionId == 9
		) {
			showLine = true;
		}
		return showLine;
	};

	const showHorizontalGraph = () => {
		let showHorizontal = false;
		if (
			questionId == 1 ||
			questionId == 5 ||
			questionId == 6 ||
			questionId == 13 ||
			questionId == 15
		) {
			showHorizontal = true;
		}
		return showHorizontal;
	};
	const showBarGraph = () => {
		let showBar = false;
		if (questionId == 10 || questionId == 11 || questionId == 12) {
			showBar = true;
		}
		return showBar;
	};
	const showHashSymbol = (question: number) => {
		let showHash = false;
		if (
			question == 1 ||
			question == 2 ||
			question == 3 ||
			question == 4 ||
			question == 7 ||
			question == 8 ||
			question == 9 ||
			question == 10 ||
			question == 11 ||
			question == 14
		) {
			showHash = true;
		}
		return showHash;
	};
	const showDollarSymbol = () => {
		let showDollar = false;
		if (
			questionId == 5 ||
			questionId == 6 ||
			questionId == 12 ||
			questionId == 13
		) {
			showDollar = true;
		}
		return showDollar;
	};
	return (
		<>
			<div
				className='questionsDrawer'
				ref={insightsRef}
				style={{
					backgroundColor:
						theme.palette.mode === 'dark'
							? '#2f2f2f'
							: theme.palette.background.alt,
					// bgcolor:theme.palette.background.light,
					borderColor: theme.palette.neutral.main,
				}}
			>
				<Grid
					//style={{ paddingBottom: '10px' }}
					container
					columnSpacing={2}
					spacing={2}
					className='questions_container'
					// rowSpacing={1}
					// rowGap={1}
				>
					<Grid
						container
						xs={12}
						className='headingContainer'
						// style={{ border: `0.5px solid ${theme.palette.neutral.main}` }}
					>
						<Grid
							xs={10}
							className='insightsText'
							color={theme.palette.text.primary}
							style={{ fontSize: theme?.typography?.dashboardFont }}
						>
							Insights
						</Grid>
						<Grid xs={1}>
							{showAnswer && (
								// <ToolTipOnText title='Back'>
								// <Button
								// 	style={{ left: '-17px' }}
								// 	className='backButtons'
								// 	type='button'
								// 	btnText=''
								// 	variant='outlined'
								// 	onClick={() => {
								// 		// dispatch(actions.questionsDrawerHandler(true));
								// 		// dispatch(actions.answerDrawerHandler(false));
								// 	}}
								// 	startIcon={<ArrowBackIcon style={{ fontSize: '25px' }} />}
								// />
								<IconButton
									className='backButtons'
									style={{ left: '-23px' }}
									onClick={() => {
										props.stateChanger(true);
										setShowAnswer(false);
										setTimeout(() => {
											let doc: any = window.document;
											const el: any = doc?.getElementById(`questions_list`);
											if (el) {
												let scrollTop = 0;
												for (let i = 1; i < questionId; i++) {
													scrollTop +=
														//@ts-ignore
														document?.getElementById(i)?.offsetHeight;
												}
												// el.scrollTop = scrollTop;
												el.scroll({
													top: scrollTop,
													behavior: 'smooth', // Add smooth scroll animation
												});
											}
										}, 100);
									}}
								>
									<ArrowBackIcon
										style={{
											fontSize: '25px',
											color: theme.palette.text.tertiary,
										}}
									/>
								</IconButton>
								// </ToolTipOnText>
							)}
						</Grid>
						<Grid xs={1}>
							{/* <ToolTipOnText title='Close'> */}
							{/* <Button
								style={{ left: '-15px' }}
								className='backButtons'
								type='button'
								btnText=''
								variant='outlined'
								onClick={() => {
									dispatch(actions.questionsDrawerHandler(false));
									dispatch(actions.answerDrawerHandler(false));
								}}
								startIcon={
									<CloseIcon style={{ fontSize: '25px', left: '-11px' }} />
								}
							/> */}
							<IconButton
								className='backButtons'
								style={{ left: '-15px' }}
								onClick={() => {
									props.stateChanger(false);
									setShowAnswer(false);
									props.viewStateChange(false);
									localStorage.setItem('isInsightsOpened', 'false');
									setTimeout(() => {
										props?.sidebarEl?.classList.remove('Sidebar-backdrop');
										let el: any = document.getElementsByTagName('body');
										if (el && el.length) el[0].style.overflowX = 'unset';
									}, 0);
								}}
							>
								<CloseIcon
									style={{
										fontSize: '25px',
										color: theme.palette.text.tertiary,
									}}
								/>
							</IconButton>
							{/* </ToolTipOnText> */}
						</Grid>
					</Grid>
					{props.showQuestions && (
						<Grid
							container
							xs={11.5}
							id='questions_list'
							style={{
								overflowY: 'auto',
								height: '100vh',
								paddingBottom: '70px',
								//marginTop: '10px',
								gridGap: '10px',
								paddingTop: '25px',
								paddingLeft: '20px',
							}}
						>
							<Grid
								container
								xs={11.5}
								style={{ height: 'fit-content' }}
								columnSpacing={3}
								spacing={3}
								rowSpacing={2}
								rowGap={2}
							>
								{appData.insightsQuestions.map((currQuest: any) => (
									<Grid
										xs={12}
										className='questions'
										style={{
											fontStyle:
												theme?.typography?.breadCrum?.breadCrumFontSize,
											border:
												theme.palette.mode === 'dark'
													? `0.5px solid ${theme.palette.neutral.main}`
													: '1px solid rgba(0, 0, 0, 0.1)',
											backgroundColor:
												theme.palette.mode === 'dark'
													? '#2f2f2f'
													: theme.palette.background.alt,
										}}
										key={currQuest.question_id}
										id={currQuest.question_id}
										color={theme.palette.text.primary}
										onClick={() => {
											getInsightsAnswer(currQuest.question_id);
											setQuestionText(currQuest.question);
											setQuestionId(currQuest.question_id);
											setShowAnswer(true);
											props.stateChanger(false);
										}}
									>
										{currQuest.question}
									</Grid>
								))}
							</Grid>
						</Grid>
					)}

					{showAnswer && (
						<>
							<Grid
								container
								xs={12}
								style={{
									overflowY: 'auto',
									height: '100vh',
									paddingBottom: '70px',
									marginTop: '10px',
								}}
								columnSpacing={2}
								spacing={2}
								rowSpacing={1}
								rowGap={1}
							>
								<Grid
									container
									xs={12}
									className='answerDrawer'
									rowSpacing={3}
									rowGap={3}
									style={{ height: 'fit-content' }}
								>
									<Grid
										className='question'
										color={theme.palette.text.primary}
										style={{
											borderColor: theme.palette.neutral.main,
											color: theme.palette.text.primary,
										}}
									>
										{questionText}
									</Grid>
									<Grid
										className='dateRange'
										style={{
											fontSize: theme?.typography?.barGraph?.legendFont,
										}}
									>{`${startDate} to ${endDate}`}</Grid>
									<Grid
										container
										xs={12}
										//className='answerDrawer'
										rowSpacing={3}
										// style={{ paddingTop: '24px' }}
										rowGap={3}
										color={theme.palette.text.primary}
									>
										<Grid
											color={theme.palette.text.primary}
											className='answer'
											style={{
												fontSize: theme?.typography?.barGraph?.legendFont,
											}}
											item
											dangerouslySetInnerHTML={{
												__html: insightAnswers(questionId, questionResponse),
											}}
										>
											{/* {insightAnswers(questionId, questionResponse)} */}
											{/* On an average 2 leads (that entered sales funnel) are
										required to close an opportunity. Out of the total 41 leads,
										18 got closed in the last 6 months. */}
										</Grid>
										{questionId != 14 && (
											<Grid
												item
												rowSpacing={0}
												rowGap={0}
												className='graphName'
												style={{
													fontSize: theme?.typography?.tabPerformance?.fontSize,
												}}
											>
												{graphName}
											</Grid>
										)}
										{questionId == 14 ? (
											<>
												{urlsGraphs.map((currentURL: any, index: number) => (
													<>
														<Grid
															className='graphName'
															style={{
																fontSize:
																	theme?.typography?.tabPerformance?.fontSize,
															}}
														>
															{currentURL.name}
														</Grid>
														<Grid
															className='graphBorder'
															xs={11}
															item
															style={{
																paddingTop: showLineGraph() ? '6px' : '-1px',
																height: 'auto',
																minHeight: '10em',
																// height:
																// 	showHorizontalGraph() && seriesLabels.length >= 5
																// 		? 'auto'
																// 		: showBarGraph()
																// 		? 'auto'
																// 		: '15em',
															}}
														>
															{currentURL.condition ? (
																<Grid>
																	{/* <Grid>{currentURL.name}</Grid> */}
																	<Grid key={Math.random()}>
																		<BarGraph
																			height={300}
																			labels={currentURL.labels}
																			series={currentURL.series}
																			yAxisTitle={currentURL.yAxisTitle}
																			fromInsights={true}
																			backgroundColor={'#2f2f2f'}
																			addRotation={true}
																			title=''
																			colors={['#9149ff']}
																			showHash={showHashSymbol(questionId)}
																		/>
																	</Grid>
																</Grid>
															) : (
																<div
																	className='insightsnoData'
																	style={{
																		fontSize:
																			theme?.typography?.barGraph?.noDataFont,
																	}}
																>
																	No data to display
																</div>
															)}
														</Grid>
													</>
												))}
												{!urlsGraphs.length && (
													<Grid
														className='graphBorder'
														xs={11}
														item
														style={{
															paddingTop: showLineGraph() ? '6px' : '-1px',
															height: 'auto',
															minHeight: '10em',
														}}
													>
														<div
															className='insightsnoData'
															style={{
																fontSize:
																	theme?.typography?.barGraph?.noDataFont,
															}}
														>
															No data to display
														</div>
													</Grid>
												)}
											</>
										) : (
											<Grid
												className='graphBorder'
												xs={11}
												item
												style={{
													paddingTop: showLineGraph() ? '6px' : '-1px',
													height: 'auto',
													minHeight: '10em',
													// height:
													// 	showHorizontalGraph() && seriesLabels.length >= 5
													// 		? 'auto'
													// 		: showBarGraph()
													// 		? 'auto'
													// 		: '15em',
												}}
											>
												{showHorizontalGraph() &&
													(showGraph ? (
														<HighchartsReact
															highcharts={Highcharts}
															options={{ ...barGraphOptions }}
														/>
													) : (
														<div
															className='insightsnoData'
															style={{
																fontSize:
																	theme?.typography?.barGraph?.noDataFont,
															}}
														>
															No data to display
														</div>
													))}

												{showLineGraph() &&
													(!showLGraph ? (
														<div
															className='insightsnoData'
															style={{
																fontSize:
																	theme?.typography?.barGraph?.noDataFont,
															}}
														>
															No data to display
														</div>
													) : (
														<LineChart
															minHeight={'unset'}
															height={
																questionId == 7 ||
																questionId == 8 ||
																questionId == 9
																	? 300
																	: 200
															}
															primaryYAxisName=''
															secondaryYAxisName=''
															primaryYAxisTitle=''
															xAxisTitle=''
															secondaryYAxisType='line'
															secondaryYAxisData={lineData}
															showPrimaryAxisMarker
															xAxisCategories={seriesLabels}
															showPrimaryAxisLegend={false}
															showSecondaryAxisLegend={false}
															showSecondaryAxisMarker
															backgroundColor={'#2f2f2f'}
															multilineChart={multilineChart}
															colors={['#9149FF', '#02BC77', '#FF9C7F']}
															showHash={showHashSymbol(questionId)}
															fromInsights={true}
														/>
													))}
												{showBarGraph() &&
													(isGraphLoading ? (
														<div
															className='insightsnoData'
															style={{
																fontSize:
																	theme?.typography?.barGraph?.noDataFont,
															}}
														>
															No data to display
														</div>
													) : (
														<BarGraph
															height={300}
															labels={seriesLabels}
															series={series}
															yAxisTitle=''
															title=''
															fromInsights={true}
															backgroundColor={'#2f2f2f'}
															isTargetGraph={true}
															colors={['#06e88a', '#FF0000', '#9149ff']}
															showHash={showHashSymbol(questionId)}
														/>
													))}
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
			</div>
		</>
	);
};
export default InsightsDrawer;
