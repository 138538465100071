import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Grid, IconButton, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Suspense, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useAppContext } from '../../AppContext/App.context';
import { IFilterModal } from '../../containers/Reports/IReports';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import MultiSelectDropdown from '../Dropdown/MultiSelectDropdown';
import Input from '../Input/Input';
import useToast from '../Toast/hooks/useToast';
import ToolTipOnText from '../Tooltip/Tooltip';
import Dimensions from './Dimensions';
import Avatar from '@mui/material/Avatar';
import FilterIcon from '../../assets/Images/filterIconSvg';
import './FilterModal.scss';
import { useTheme } from '@mui/styles';
import AlertDialog from '../Dialog/Dialog';

interface IFilterModalProps {
	filters: any;
	selectedFilters?: Object;
	onReset: Function;
	onSubmit: Function;
	showRadioFilter?: boolean;
	disableAndRelation?: boolean;
	hideOrTab?: boolean;
	isDisabled?: boolean;
	showSegments?: boolean;
	allItemsAreSelected?: string;
	selectAllText?: string;
	updateResetState?: any;
	disableMultiSelect?: boolean;
	type?: any;
	customParameterList?: any;
	showCustomType?: any;
	campaignTypeArr?: any;
}

const FilterModal: React.FC<IFilterModalProps> = (props: any) => {
	const toaster: any = useToast();
	const theme: any = useTheme();
	const [handleOpenDialog, setShowModal] = useState(false);
	const [appData]: any = useAppContext();
	const { segmentsList } = appData;

	const [filters, setFilters]: any = useState<Array<IFilterModal>>([]);
	const [appendSegmentInFilter, setAppendSegmentInFilter] = useState(
		props.showSegments || false
	);
	let [selectedFilters, setSelectedFilters]: any = useState(props.se);
	let [validationETM, setValidationETM]: any = useState(false);
	let [dimensionValidation, setDimensionValidation]: any = useState(-1);
	let [showFilterApplied, setShowFilterApplied]: any = useState(false);
	const [disablePointerEvents, setDisablePointerEvents]: any = useState(false);
	const [salesTouchRadio, setSalesTouchRadio]: any = useState('no');
	const filterButtonHandler = () => setShowModal(true);
	const resetFilterRef: any = useRef();
	const handleCloseDialog = () => {
		setSelectedFilters(props.selectedFilters);
		setShowModal(false);
	};

	useEffect(() => {
		if (props.filters.length > 0) {
			// I use ID to create a selected filter component that will be passed on submit.
			if (!props.selectedFilters) {
				props.filters.map((value: any) => {
					selectedFilters[value.id] = '';
				});
				setSelectedFilters({ ...selectedFilters });
			}
			// if(props?.showCustomType) {
			// 	const existingFilter = props.filters;
			// 	const typeFilter = {
			// 		name: 'Type',
			// 		data: props?.customParameterList,
			// 		type: 'dropdown',
			// 		labelName: 'Type',
			// 		id: 'type',
			// 	};
			// 	setFilters([typeFilter, ...existingFilter]);
			// }
			if (props.showSegments && segmentsList.length > 0) {
				const existingFilter = props.filters;
				const segmentFilter = {
					name: 'Segment Type',
					data: segmentsList.map((item: any) => ({
						name: item.segment,
						value: item.segment_id,
					})),
					type: 'dropdown',
					labelName: 'Segment Type',
					id: 'segment_id',
				};
				setFilters([segmentFilter, ...existingFilter]);
			} else {
				setSalesTouchRadio(props.selectedFilters.sales_touches);
				setFilters([...props.filters]);
			}
		}
	}, [props.filters]);
	useEffect(() => {
		if (props?.selectedFilters) {
			// ['SD', 'ED', AM, 'DT"]
			const defaultFilter: any = props?.onReset() || {};
			let tempFlag = false;
			Object.keys(props.selectedFilters).find((key: any) => {
				if (
					key === 'start_date' ||
					key === 'end_date' ||
					key === 'ad_account' ||
					key === 'segment_id' ||
					key === 'sales_touches' ||
					key === 'source_type' ||
					key === 'bing_sites' ||
					key === 'google_site' ||
					key === 'filters' ||
					key === 'isReload' ||
					key === 'showTabularData'
				) {
					return false;
				}
				if (key === 'conditions' && props.selectedFilters[key]?.length > 0) {
					tempFlag = true;
					return true;
				}
				// else if(key === 'ad_account' && props.selectedFilters[key].length > 0){
				//   tempFlag = true;
				//   return true;
				// }
				if (
					props.selectedFilters[key] &&
					props.selectedFilters[key] !== defaultFilter[key] &&
					key !== 'conditions'
				) {
					tempFlag = true;
					return true;
				}
				tempFlag = false;
			});
			setShowFilterApplied(tempFlag);
			if (props.updateResetState) {
				props.updateResetState(tempFlag);
			}
			selectedFilters = props.selectedFilters;
			setSelectedFilters({ ...selectedFilters });
		}
	}, [props.selectedFilters]);

	const assignKeyname = (val: string) => {
		switch (val) {
			case 'Attribution Model':
				return 'attribution_model';
			case 'Segment Type':
				return 'segment_id';
			case 'Date Type':
				return 'date_type';
			case 'Connectors':
				return 'connector';
			default:
				return '';
		}
	};

	const isFilterDefault = (id: any): boolean => {
		const defaultFilter = props.filters.find((item: any) => item.id === id);
		if (defaultFilter?.required || defaultFilter?.isDisabled) {
			return false;
		}
		return true;
	};

	// Types
	function FilterType(filter: any) {
		switch (filter.type) {
			case 'dropdown':
				return (
					<Grid item xs={5} className='filter_dropdown_container'>
						<Dropdown
							itemList={filter.data}
							label='name'
							labelName={filter.name || 'name'}
							disabled={filter.isDisabled}
							valueSelector='value'
							name={filter.name}
							defaultValue={selectedFilters[filter.id]}
							onChange={(evt: any) => onFilterUpdated(evt, filter.id)}
							error={
								!selectedFilters[filter.id] && validationETM && filter?.required
							}
						/>
						{selectedFilters[filter.id] && isFilterDefault(filter.id) ? (
							<div
								className='clear_btn_icon'
								onClick={() => {
									const keyName = assignKeyname(filter.name);
									if (filter.name === 'Segment Type') {
										setSelectedFilters({
											...selectedFilters,
											[`${keyName}`]: null,
										});
										return;
									}
									setSelectedFilters({
										...selectedFilters,
										[`${keyName}`]: '',
									});
								}}
								role='button'
								tabIndex={0}
								onKeyDown={() => {}}
							>
								<ToolTipOnText title={`Clear ${filter.name || ''}`}>
									<ClearIcon style={{ color: 'grey' }} />
								</ToolTipOnText>
							</div>
						) : (
							<></>
						)}
					</Grid>
				);
			case 'conditional':
				return (
					<Dimensions
						selector={filter.id}
						customParameterList={props?.customParameterList ? true : false}
						parameterList={
							props?.customParameterList
								? props?.customParameterList
								: filter?.parameters
						}
						operatorList={filter.operators}
						selectedConditions={selectedFilters[filter?.id]}
						onConditionUpdated={(conditions: any) =>
							onConditionUpdated(conditions, filter?.id)
						}
						dimensionError={dimensionValidation}
						hideOrTab={props.hideOrTab ? true : false}
						campaignTypeArr={props.campaignTypeArr}
					/>
				);
			// case 'multi-select':
			//   return (
			//     <Grid item xs={5} style={{ position: 'relative', zIndex: '99999' }}>
			//       <MultiSelectDropdown
			//         itemList={filter.data}
			//         onChange={(evt: any) => {onFilterUpdated(evt, filter.id)}}
			//         selected={selectedFilters[filter.id]}
			//       />
			//     </Grid>
			//   )
			case 'text':
				return <Input name='' placeholder='Enter Value' />;
			default:
				return <></>;
		}
	}

	// Events
	const onFilterUpdated = (evt: any, valueOF: any) => {
		selectedFilters[valueOF] = evt?.target?.value || evt;
		setSelectedFilters({ ...selectedFilters });
	};
	const onConditionUpdated = (conditions: Object[], name: any) => {
		selectedFilters[name] = [...conditions];
	};
	const onReset = () => {
		// This should return default values as user can cancel reset as well
		setSelectedFilters({ ...props.onReset() });
		setDimensionValidation(-1);
		setValidationETM(false);
	};

	const submitFilters = () => {
		setValidationETM(true);
		showFilterApplied = false;
		setShowFilterApplied(showFilterApplied);
		let dimensionError = -1;
		selectedFilters?.conditions?.find((condition: any, dimensionIndex: any) => {
			if (!condition.operator) {
				dimensionError = dimensionIndex;
				return true;
			}
			// if (condition.type !== 1  && condition.type !== 0) {
			// 		dimensionError = dimensionIndex;
			// 		return true;
			// 	}
			if (!condition.parameter) {
				dimensionError = dimensionIndex;
				return true;
			}
			if (!condition.value) {
				dimensionError = dimensionIndex;
				return true;
			}
			if (!condition.value) {
				dimensionError = dimensionIndex;
				return true;
			}
			showFilterApplied = true;
			setShowFilterApplied(showFilterApplied);
		});
		if (dimensionError !== -1) {
			setDimensionValidation(dimensionError);
			return;
		}

		// const typeCheck = props?.customParameterList;
		// if(typeCheck.isArray && typeCheck.length > 0)

		// check for ad account dropdown - if empty in pmt report
		const checkIfAccountId = selectedFilters.hasOwnProperty('ad_account');

		if (checkIfAccountId && selectedFilters?.ad_account?.length === 0) {
			toaster.addToast({
				message: 'Please select at least one Ad Account ID',
				timeout: 3000,
				type: 'error',
			});
			return;
		}
		// const checkIfBingConsoleSites =
		// 	selectedFilters.hasOwnProperty('bing_sites');
		// if (
		// 	checkIfBingConsoleSites &&
		// 	selectedFilters?.bing_sites?.length === 0 &&
		// 	selectedFilters.google_site === ''
		// ) {
		// 	toaster.addToast({
		// 		message: 'Please select at least one site',
		// 		timeout: 3000,
		// 		type: 'error',
		// 	});
		// 	// return;
		// }
		const isValidationFalse = filters.find((index: any) => {
			if (index.required && !selectedFilters[index.id]) {
				return true;
			}
		});

		if (isValidationFalse) {
			return;
		}
		if (props.showRadioFilter) {
			selectedFilters.sales_touches = salesTouchRadio;
		}
		props.onSubmit(selectedFilters);
		if (props.updateResetState) {
			props.updateResetState(showFilterApplied);
		}
		handleCloseDialog();
	};

	const onResetFilters = () => {
		selectedFilters = { ...props.onReset() };
		dimensionValidation = -1;
		validationETM = false;
		submitFilters();
		setSelectedFilters({ ...selectedFilters });
		setDimensionValidation(-1);
		setValidationETM(false);
		setShowFilterApplied(false);
	};

	const salesTouch = (event: any) => {
		setSalesTouchRadio(event.target.value);
		selectedFilters.sales_touches = salesTouchRadio;
		setSelectedFilters({ ...selectedFilters });
	};
	const data = {
		fill: theme.palette.text.tertiary,
		width: '20px',
		height: '20px',
	};
	return (
		<div className='filterModal' style={{ position: 'relative' }}>
			{/* <Badge color='primary' variant='dot' > */}
			{showFilterApplied ? (
				<div
					className='circlePosition'
					onClick={() => {
						props?.customParameterList ||
						props?.showCustomType ||
						props?.campaignTypeArr
							? resetFilterRef.current.handleClickOpen()
							: onResetFilters();
					}}
				>
					<ToolTipOnText title='Reset' placement='bottom'>
						<CancelIcon
							// onClick={onResetFilters}
							fontSize='small'
							onFocus={() => {
								setDisablePointerEvents(true);
							}}
							onBlur={() => {
								setDisablePointerEvents(false);
							}}
							className='circlePosition__svg'
							color='warning'
							style={{ fill: '#cc3333' }}
						/>
					</ToolTipOnText>
				</div>
			) : (
				''
			)}

			<Grid
				onClick={() => {
					filterButtonHandler();
				}}
				className={'filterIcon'}
				style={{
					cursor: 'pointer',
					position: 'relative',
					// marginRight: theme.palette.mode === 'dark' ? '12px' : '',
				}}
				mr={3.5}
				ml={-1.0}
				aria-disabled={
					props.isDisabled ? props.isDisabled : disablePointerEvents
				}
			>
				<ToolTipOnText
					title='Filters'
					style={{
						display: 'flex',
						padding: '4px',
					}}
				>
					{/* <img
						className='iconColor'
						src={filterIcon}
						style={{ height: '20px', width: '20px' }}
					></img> */}
					<FilterIcon {...data} />
				</ToolTipOnText>
			</Grid>
			<Modal open={handleOpenDialog} onClose={handleCloseDialog}>
				<Box
					className={
						props.type
							? 'socialMedia__modalContainer'
							: 'filterModal__modalContainer'
					}
					bgcolor={theme.palette.background.light}
					border={`1px solid`}
					borderColor={theme.palette.neutral.main}
				>
					<IconButton
						className='filterModal__closeBtn'
						onClick={handleCloseDialog}
					>
						<CancelIcon />
					</IconButton>

					<Grid
						container
						style={{
							minHeight: props.type ? '30vh' : '54vh',
							display: 'block',
						}}
					>
						<Typography variant='h4' component='div' align='left'>
							Filters
						</Typography>
						{props.showRadioFilter ? (
							<FormControl
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									marginTop: '1.5rem',
									flexDirection: 'row',
								}}
							>
								<Typography variant='h5' component='div' align='left'>
									Include Sales Touch
								</Typography>
								<RadioGroup
									row
									style={{ marginLeft: '3rem' }}
									defaultValue='no'
									onChange={salesTouch}
									value={salesTouchRadio}
								>
									<FormControlLabel
										value='yes'
										control={<Radio />}
										label='Yes'
									/>
									<FormControlLabel value='no' control={<Radio />} label='No' />
								</RadioGroup>
							</FormControl>
						) : (
							''
						)}
						{filters?.map((filter: any, index: any) => (
							<Grid
								container
								xs={12}
								mt={3}
								key={index}
								alignItems={
									filter.name !== 'Dimensions' ? 'center' : 'flex-start'
								}
							>
								<Grid item xl={2.5} lg={2.5}>
									<Typography
										variant='h5'
										component='div'
										style={
											filter.name === 'Dimensions'
												? { marginTop: '0.5rem' }
												: {}
										}
									>
										{filter?.name}
									</Typography>
								</Grid>
								<Grid
									item
									xl={9.5}
									lg={9.5}
									className={
										filter.name === 'Dimensions'
											? 'filterModal__modalContainer__dimension'
											: ''
									}
								>
									<FilterType {...filter} />
									{filter.type === 'multi-select' ? (
										<Grid
											item
											xs={5}
											style={{ position: 'relative', zIndex: '99999' }}
										>
											<MultiSelectDropdown
												itemList={filter.data}
												onChange={(evt: any) => {
													onFilterUpdated(evt, filter.id);
												}}
												allSelectedPlaceholder={filter.allSelectedPlaceholder}
												selectAllText={filter.selectAllText}
												selected={selectedFilters[filter.id]}
												disable={!!filter.disableMultiSelect}
											/>
										</Grid>
									) : null}
								</Grid>
							</Grid>
						))}
					</Grid>
					<Grid container justifyContent='end'>
						<Button
							btnText='RESET'
							type='button'
							variant='outlined'
							onClick={onReset}
						/>
						<Button btnText='APPLY' type='button' onClick={submitFilters} />
					</Grid>
				</Box>
			</Modal>
			<React.Suspense fallback={<h3>Loading&hellip;</h3>}>
				<AlertDialog
					ref={resetFilterRef}
					title='Reset Filters'
					description='Are you sure you want to reset filters for report?'
					confirm='Reset'
					discard='Cancel'
					color='error'
					hideCloseButton
					onConfirm={() => {
						onResetFilters();
						resetFilterRef.current.handleClose();
					}}
					onDiscard={() => {
						resetFilterRef.current.handleClose();
					}}
				/>
			</React.Suspense>
		</div>
	);
};

export default FilterModal;
