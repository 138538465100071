/* eslint-disable one-var */
import {
	Checkbox,
	ClickAwayListener,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Menu,
	Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import {
	addDays,
	addYears,
	differenceInCalendarDays,
	endOfDay,
	endOfYear,
	isSameDay,
	startOfDay,
	startOfYear,
} from 'date-fns';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import {
	DateRangePicker,
	defaultInputRanges,
	defaultStaticRanges,
} from 'react-date-range';
import { useLocation } from 'react-router-dom';

import CalendarIcon from '../../assets/Images/CalendarIcon';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { DateTimeFormatter } from '../../utils/DateFormatter';
import useLocalStorage from '../../utils/localStorage';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import { IDateRange } from './IDateRangePicker';
import { IDatePickerProps, IRangePickerFormats } from './IDateRangePicker';

import './ReactDateRangePicker.scss';
import 'react-date-range/dist/styles.css';
// main css file
import 'react-date-range/dist/theme/default.css';

const ReactDatePicker: React.FC<IDatePickerProps> = (props: any) => {
	const { width } = useWindowDimensions();
	const theme: any = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	// In some reports there is not enough space for full format. hence, MMM is used.
	const format =
		width > 1280
			? IRangePickerFormats.range_med
			: IRangePickerFormats.range_responsive;
	const selectedDefaultMonths = props.defaultMonths ? props.defaultMonths : 6;
	const [showComparison, setShowComparison] = useState(
		// !!useLocalStorage.getItem('date_comparison')
		!!props?.showComparison
	);
	const [customRanges, setCustomRanges] = useState(defaultStaticRanges);
	const [inputRange, setInputRange] = useState([]);
	const [isCompared, setIsCompared] = useState(
		useLocalStorage.getItem('date_comparison') || false
	);
	const storedFont = useLocalStorage.getItem('selectedfontSize');
	let [saveDateForLabel, setSaveDateforLabel]: any = useState({
		startDate: '',
		endDate: '',
	});
	// Default Range
	const currentDate = new Date();
	const currentDate2 = new Date();
	const currentDate3 = new Date();

	const [displayDate, setDisplayDate] = useState({
		startDate: new Date(
			currentDate.setMonth(currentDate.getMonth() - selectedDefaultMonths)
		),
		endDate: new Date(),
	});
	const [isPreviousYear, setIsPreviousYear]: any = useState(true);
	const [isDateApplied, setIsDateApplied] = useState(false);
	const [comparisionDisplayDate, setComparisonDisplayDate] = useState({
		startDate: new Date(
			currentDate2.setMonth(
				currentDate2.getMonth() - selectedDefaultMonths - selectedDefaultMonths
			)
		),
		endDate: new Date(
			currentDate3.setMonth(currentDate3.getMonth() - selectedDefaultMonths)
		),
	});

	const [applyButtonDisable, setApplyButtonDisable] = useState(true);
	const [isMultipleRange, setIsMultipleRange] = useState(
		!!props?.isMultipleRanges
	);
	const defaultDate: any = useRef({});

	let [dateRange, setDateRange]: any = useState({
		selection1: {
			startDate: new Date(
				currentDate.setMonth(currentDate.getMonth() - selectedDefaultMonths)
			),
			endDate: new Date(),
			key: 'selection1',
			label: customRanges[0].label,
		},
		selection2: {
			startDate: new Date(
				currentDate2.setMonth(
					currentDate2.getMonth() -
						selectedDefaultMonths -
						selectedDefaultMonths
				)
			),
			endDate: new Date(
				currentDate3.setMonth(currentDate3.getMonth() - selectedDefaultMonths)
			),
			key: 'selection2',
		},
	});
	const [dateRangenew, setDateRangeNew]: any = useState({
		selection1: {
			startDate: new Date(
				currentDate.setMonth(currentDate.getMonth() - selectedDefaultMonths)
			),
			endDate: new Date(),
			key: 'selection1',
		},
		selection2: {
			startDate: new Date(
				currentDate2.setMonth(
					currentDate2.getMonth() -
						selectedDefaultMonths -
						selectedDefaultMonths
				)
			),
			endDate: new Date(
				currentDate3.setMonth(currentDate3.getMonth() - selectedDefaultMonths)
			),
			key: 'selection2',
		},
	});

	const [selectedOption, setSelectedOption]: any = useState(
		useLocalStorage.getItem('timePeriod') || 2
	);

	const location = useLocation();
	const [fromBookmark, setFromBookmark] = useState(false);
	const dateRangeLocal: any = useLocalStorage.getItem('dateRange');
	const [isAllQuarter, setIsAllQuarter]: any = useState(
		dateRangeLocal?.isAllQuarter || false
	);

	const [selectedYear, setSelectedYear]: any = useState();

	useEffect(() => {
		if (dateRangeLocal?.selectedYear) {
			setSelectedYear(dateRangeLocal?.selectedYear);
		} else {
			setSelectedYear(new Date().getFullYear());
		}
	}, []);

	useEffect(() => {
		if (
			location.search &&
			location.search?.split('=')[0].includes('?bookmarkId')
		) {
			setFromBookmark(true);
		}
	}, []);

	function firstDayOfWeek(dateObject: any, firstDayOfWeekIndex: any) {
		const dayOfWeek = dateObject.getDay(),
			firstDayOfWeek = new Date(dateObject),
			diff =
				dayOfWeek >= firstDayOfWeekIndex
					? dayOfWeek - firstDayOfWeekIndex
					: 6 - dayOfWeek;

		firstDayOfWeek.setDate(dateObject.getDate() - diff);
		firstDayOfWeek.setHours(0, 0, 0, 0);

		return firstDayOfWeek;
	}

	function firstDayofLastWeek(dateObject: any, firstDayOfWeekIndex: any) {
		const dayOfWeek = dateObject.getDay(),
			firstDayOfWeek = new Date(dateObject),
			diff =
				dayOfWeek >= firstDayOfWeekIndex
					? dayOfWeek - firstDayOfWeekIndex
					: 13 - dayOfWeek;

		firstDayOfWeek.setDate(dateObject.getDate() - diff);
		firstDayOfWeek.setHours(0, 0, 0, 0);

		return firstDayOfWeek;
	}
	const saveGlobalDates: any = fromBookmark
		? { start_date: props.start_date, end_date: props.end_date }
		: !props.dontSaveInLS
		? useLocalStorage.getItem('dateRange')
		: { start_date: props.start_date, end_date: props.end_date };

	// Define the function to get the start and end dates for a specific quarter.
	function getQuarterDates(quarterNumber: number, year: number) {
		const startDate = moment()
			.quarter(quarterNumber)
			.year(year)
			.startOf('quarter');
		const endDate = moment().quarter(quarterNumber).year(year).endOf('quarter');
		return { startDate, endDate };
	}

	useEffect(() => {
		const defineds: any = {
			startOfToday: startOfDay(new Date()),
			endOfToday: endOfDay(new Date()),
		};

		setIsMultipleRange(props.isMultipleRanges);
		// Setting
		const tempRanges: any = [...defaultStaticRanges];
		tempRanges.splice(0, 2);

		const thisWeekIndex = tempRanges.findIndex(
			(item: any) => item.label === 'This Week'
		);

		tempRanges[thisWeekIndex] = {
			label: 'This Week',
			range: () => ({
				startDate: (() => firstDayOfWeek(new Date(), 0))(),
				endDate: new Date(),
			}),
			hasCustomRendering: true,

			isSelected(range: any) {
				const definedRange: any = this.range();
				return (
					isSameDay(range.startDate, definedRange.startDate) &&
					isSameDay(range.endDate, definedRange.endDate)
				);
			},
		};

		const thisMonthIndex = tempRanges.findIndex(
			(item: any) => item.label === 'This Month'
		);

		tempRanges[thisMonthIndex] = {
			label: 'This Month',
			range: () => ({
				startDate: (() => {
					const now = new Date();
					const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
					return firstDay;
				})(),
				endDate: new Date(),
			}),
			hasCustomRendering: true,

			isSelected(range: any) {
				const definedRange: any = this.range();
				return (
					isSameDay(range.startDate, definedRange.startDate) &&
					isSameDay(range.endDate, definedRange.endDate)
				);
			},
		};

		tempRanges.push({
			label: `This Year (${moment()
				.startOf('year')
				.format('MMM,YYYY')} - ${moment(new Date()).format('MMM,YYYY')})`,
			range: () => ({
				startDate: startOfYear(new Date()),
				endDate: endOfDay(new Date()),
			}),
			hasCustomRendering: true,

			isSelected(range: any) {
				const definedRange: any = this.range();
				return (
					isSameDay(range.startDate, definedRange.startDate) &&
					isSameDay(range.endDate, definedRange.endDate)
				);
			},
		});

		tempRanges.push({
			label: `Last Year (${moment(moment().startOf('year'))
				.subtract(12, 'months')
				.format('MMM,YYYY')} - ${moment([lastyear])
				.endOf('year')
				.format('MMM,YYYY')})`,
			range: () => ({
				startDate: startOfYear(addYears(new Date(), -1)),
				endDate: endOfYear(addYears(new Date(), -1)),
			}),
			hasCustomRendering: true,
			isSelected(range: any) {
				const definedRange: any = this.range();
				return (
					isSameDay(range.startDate, definedRange.startDate) &&
					isSameDay(range.endDate, definedRange.endDate)
				);
			},
		});
		tempRanges.push({
			label: `Last 365 Days (${moment(new Date())
				.subtract(12, 'months')
				.format('MMM,YYYY')} - ${moment(new Date()).format('MMM,YYYY')})`,
			range: () => ({
				startDate: (() => {
					let now = new Date();
					let duedate = new Date(now);
					duedate.setDate(now.getDate() - 365);
					return duedate;
				})(),
				endDate: new Date(),
			}),
			hasCustomRendering: true,

			isSelected(range: any) {
				const definedRange: any = this.range();
				return (
					isSameDay(range.startDate, definedRange.startDate) &&
					isSameDay(range.endDate, definedRange.endDate)
				);
			},
		});

		tempRanges.push({
			label: `Last 2 Years (${moment(new Date())
				.subtract(24, 'months')
				.format('MMM,YYYY')} - ${moment(new Date()).format('MMM,YYYY')})`,
			range: () => ({
				startDate: (() => {
					let now = new Date();
					let duedate = new Date(now);
					duedate.setDate(now.getDate() - 730);
					return duedate;
				})(),
				endDate: new Date(),
			}),
			hasCustomRendering: true,

			isSelected(range: any) {
				const definedRange: any = this.range();
				return (
					isSameDay(range.startDate, definedRange.startDate) &&
					isSameDay(range.endDate, definedRange.endDate)
				);
			},
		});

		if (props.showQuatreOptions) {
			// Current Quarter
			const currentQuarterStart = moment().startOf('quarter');
			const currentQuarterEnd = moment().endOf('quarter');
			tempRanges.push({
				label: `Current Qtr. (${currentQuarterStart.format(
					'MMM,YYYY'
				)} - ${currentQuarterEnd.format('MMM,YYYY')})`,
				range: () => ({
					startDate: currentQuarterStart.toDate(),
					endDate: currentQuarterEnd.toDate(),
				}),
				hasCustomRendering: true,
				isSelected(range: any) {
					const definedRange: any = this.range();
					return (
						isSameDay(range.startDate, definedRange.startDate) &&
						isSameDay(range.endDate, definedRange.endDate)
					);
				},
			});

			// Previous Quarter
			const previousQuarterStart = moment()
				.startOf('quarter')
				.subtract(3, 'months');
			const previousQuarterEnd = moment()
				.endOf('quarter')
				.subtract(3, 'months')
				.add(1, 'day');
			tempRanges.push({
				label: `Prev Qtr. (${previousQuarterStart.format(
					'MMM,YYYY'
				)} - ${previousQuarterEnd.format('MMM,YYYY')})`,
				range: () => ({
					startDate: previousQuarterStart.toDate(),
					endDate: previousQuarterEnd.toDate(),
				}),
				hasCustomRendering: true,
				isSelected(range: any) {
					const definedRange: any = this.range();
					return (
						isSameDay(range.startDate, definedRange.startDate) &&
						isSameDay(range.endDate, definedRange.endDate)
					);
				},
			});

			// All Quarters (2023)
			const year = moment().format('YYYY');
			tempRanges.push({
				label: '',
				uniqueKey: 'all_qtr',
				range: () => ({
					startDate: moment(year, 'YYYY').startOf('year').toDate(),
					endDate: moment(year, 'YYYY').endOf('year').toDate(),
				}),
				hasCustomRendering: true,
				isSelected(range: any) {
					const definedRange: any = this.range();
					return (
						isSameDay(range.startDate, definedRange.startDate) &&
						isSameDay(range.endDate, definedRange.endDate)
					);
				},
			});
		}

		setCustomRanges([...tempRanges]);
		const saveGlobalDates = fromBookmark
			? { start_date: props.start_date, end_date: props.end_date }
			: useLocalStorage.getItem('dateRange');
		const saveGlobalCompDates = fromBookmark
			? {
					pre_start_date: props.pre_start_date,
					pre_end_date: props.pre_end_date,
			  }
			: useLocalStorage.getItem('compdateRange');
		if (saveGlobalDates?.start_date) {
			dateRange.selection1 = {
				startDate: new Date(saveGlobalDates.start_date),
				endDate: new Date(saveGlobalDates.end_date),
				key: 'selection1',
			};
			setDisplayDate({
				startDate: new Date(saveGlobalDates.start_date),
				endDate: new Date(saveGlobalDates.end_date),
			});
		} else if (props.start_date && props.end_date) {
			dateRange.selection1 = {
				startDate: new Date(props.start_date),
				endDate: new Date(props.end_date),
				key: 'selection1',
			};
			setDisplayDate({
				startDate: new Date(props.start_date),
				endDate: new Date(props.end_date),
			});
		} else {
			dateRange.selection1 = {
				startDate: new Date(
					currentDate.setMonth(currentDate.getMonth() - selectedDefaultMonths)
				),
				endDate: new Date(),
				key: 'selection1',
			};
			setDisplayDate({
				startDate: new Date(
					currentDate.setMonth(currentDate.getMonth() - selectedDefaultMonths)
				),
				endDate: new Date(),
			});
		}
		if (saveGlobalCompDates?.pre_start_date) {
			const pre_dates = getPreviousPeriodDate(
				saveGlobalDates.start_date,
				saveGlobalDates.end_date
			);
			dateRange.selection2 = {
				startDate: new Date(
					saveGlobalCompDates?.pre_start_date || pre_dates.startDate
				),
				endDate: new Date(
					saveGlobalCompDates?.pre_end_date || pre_dates.endDate
				),
				key: 'selection2',
			};
			setComparisonDisplayDate({
				startDate: new Date(
					saveGlobalCompDates?.pre_start_date || pre_dates.startDate
				),
				endDate: new Date(
					saveGlobalCompDates?.pre_end_date || pre_dates.endDate
				),
			});
		} else {
			if (props.pre_start_date && props.pre_end_date) {
				dateRange.selection2 = {
					startDate: new Date(props.pre_start_date),
					endDate: new Date(props.pre_end_date),
					key: 'selection2',
				};
				setComparisonDisplayDate({
					startDate: new Date(props.pre_start_date),
					endDate: new Date(props.pre_end_date),
				});
			} else {
				dateRange.selection2 = {
					startDate: new Date(
						currentDate2.setMonth(
							currentDate2.getMonth() -
								selectedDefaultMonths -
								selectedDefaultMonths
						)
					),
					endDate: new Date(
						currentDate3.setMonth(
							currentDate3.getMonth() - selectedDefaultMonths
						)
					),
					key: 'selection2',
				};
				setComparisonDisplayDate({
					startDate: new Date(
						currentDate2.setMonth(
							currentDate2.getMonth() -
								selectedDefaultMonths -
								selectedDefaultMonths
						)
					),
					endDate: new Date(
						currentDate3.setMonth(
							currentDate3.getMonth() - selectedDefaultMonths
						)
					),
				});
			}
			defaultDate.current = dateRange;
		}
	}, [props.start_date, props.end_date]);
	// Events

	// var calendar = document.getElementsByClassName('ReactDateTimePicker ReactDateTimePicker__large')[0];

	// window.addEventListener('click', function (event) {
	// 	// Access the clicked element using event.target
	// 	//@ts-ignore
	// 	var isClickedInCalendar = calendar?.contains(event.target);
	// 	if (isClickedInCalendar) {
	// 		debugger;
	// 		//@ts-ignore
	// 		let clickedElement = event.target.innerText;
	// 		if (clickedElement != 'Apply') {
	// 			if (
	// 				clickedElement == 'This Week' ||
	// 				clickedElement == 'This Month' ||
	// 				clickedElement.includes('This Year') ||
	// 				clickedElement.includes('Last Year') ||
	// 				clickedElement.includes('Last 365 Days') ||
	// 				clickedElement.includes('Last 2 Years')
	// 			) {
	// 				useLocalStorage.setItem('dateRangeLabel', clickedElement);
	// 			}
	// 		}
	// 	}
	// 	return;
	// });

	const handleClose = () => {
		if (isDateApplied) {
			setIsDateApplied(false);
			setApplyButtonDisable(true);
			setAnchorEl(null);
			setIsAllQuarter(dateRangeLocal?.isAllQuarter || false);
			useLocalStorage.setItem('date_comparison', isCompared);
			setIsCompared(useLocalStorage.getItem('date_comparison'));
			return;
		}
		// useLocalStorage.setItem('date_comparison', isCompared);
		setIsCompared(useLocalStorage.getItem('date_comparison'));

		setDateRange({
			selection1: { ...displayDate, lable: customRanges, key: 'selection1' },
			selection2: { ...comparisionDisplayDate, key: 'selection2' },
		});

		// setIsDateApplied(false);
		// setApplyButtonDisable(true);
		setSelectedOption(useLocalStorage.getItem('timePeriod'));
		setAnchorEl(null);
	};
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		isDateApplied ? handleClose() : <></>;
	}, [isDateApplied]);

	const getPreviousPeriodDate = (start: any, end: any) => {
		const tempStart: any = new Date(DateTimeFormatter.getDateInFormat(start));
		const tempEnd: any = new Date(DateTimeFormatter.getDateInFormat(end));
		const diffTime = Math.abs(tempEnd - tempStart);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const prevStart = moment(start)
			.subtract(diffDays + 1, 'days')
			.toDate();
		const prevEnd = moment(start).subtract(1, 'days').toDate();
		return {
			startDate: prevStart,
			endDate: prevEnd,
			key: 'selection2',
		};
	};
	const getPreviousYear = (start: any, end: any) => {
		const prevStart = moment(start).subtract(12, 'months').toDate();
		const prevEnd = moment(end).subtract(12, 'months').toDate();
		return {
			startDate: prevStart,
			endDate: prevEnd,
			key: 'selection2',
		};
	};
	var lastyear: any = new Date(
		new Date().getFullYear() - 1,
		0,
		1
	).getFullYear();

	const getLastYear = (start = moment().startOf('year')) => {
		const yearDifference = Math.abs(selectedYear - new Date().getFullYear());
		const subtractionMonth = yearDifference == 0 ? 12 : 24;
		var preStart = moment(start)
				.subtract(Math.abs(subtractionMonth), 'months')
				.toDate(),
			end = moment([yearDifference == 0 ? lastyear : lastyear - 1])
				.endOf('year')
				.toDate();
		return {
			startDate: preStart,
			endDate: end,
			key: 'selection2',
		};
	};
	const onOptionSelection = (evt: any) => {
		setApplyButtonDisable(false);
		if (evt.target.value === 1) {
			setDateRange({
				selection1: {
					startDate: dateRange.selection1.startDate,
					endDate: dateRange.selection1.endDate,
					key: 'selection1',
				},
				selection2: getPreviousPeriodDate(
					dateRange.selection1.startDate,
					dateRange.selection1.endDate
				),
			});
		} else if (evt.target.value === 3) {
			setDateRange({
				selection1: {
					startDate: dateRange.selection1.startDate,
					endDate: dateRange.selection1.endDate,
					key: 'selection1',
				},
				selection2: getPreviousYear(
					dateRange.selection1.startDate,
					dateRange.selection1.endDate
				),
			});
		} else if (evt.target.value === 4) {
			setDateRange({
				selection1: {
					startDate: dateRange.selection1.startDate,
					endDate: dateRange.selection1.endDate,
					key: 'selection1',
				},
				selection2: getLastYear(),
			});
		}
		setSelectedOption(evt.target.value);
	};

	const [allQtrColor, setAllQtrColor]: any = useState(
		dateRangeLocal?.isAllQuarter ? 'rgb(230, 0, 255)' : theme.palette.color
	);

	// build

	const handleRanges = (range: any): any => {
		let dateRangeObj: any;
		try {
			const first: any = range?.label?.split('(');
			return (
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '5px',
							padding: props.showQuatreOptions ? '10px 20px' : '',
							alignItems: 'center',
						}}
						onClick={() => {
							if (range.uniqueKey == 'all_qtr' && !dateRangeObj) {
								setAllQtrColor('rgb(230, 0, 255)');
								let yearDifference = new Date().getFullYear() - selectedYear;
								const newDate = new Date();
								const firstDateOfYear = new Date(newDate.getFullYear(), 0, 1);
								let lastYearDate: any = new Date(firstDateOfYear);
								lastYearDate.setFullYear(
									firstDateOfYear.getFullYear() - yearDifference
								);
								let yearLast1: any;
								if (yearDifference > 0) {
									yearLast1 = new Date(new Date().getFullYear() - 1, 11, 31);
								} else {
									yearLast1 = new Date(new Date().getFullYear(), 11, 31);
								}
								let newObject = {
									startDate: lastYearDate,
									endDate: yearLast1,
									key: 'selection1',
								};
								let tempDateRange = { ...dateRange };
								tempDateRange.selection1 = newObject;
								setTimeout(() => {
									setDateRange(tempDateRange);
									// setIsPreviousYear(true);
									setDateRangeNew(tempDateRange);
									setIsAllQuarter(true);
								}, 0);
							}
						}}
						// style={{ }}
					>
						{range.uniqueKey == 'all_qtr' ? (
							<>
								{' '}
								<div style={{ color: allQtrColor }}>All Qtr.</div>
								<div style={{ width: '150px' }}>
									<Dropdown
										valueSelector='value'
										itemList={[
											{
												name: 'CY ' + '(' + new Date().getFullYear() + ')',
												value: new Date().getFullYear(),
											},
											{
												name:
													'PY ' + '(' + (new Date().getFullYear() - 1) + ')',
												value: new Date().getFullYear() - 1,
											},
										]}
										label='name'
										selectedValue={selectedYear}
										name='Type'
										// labelName='Select'
										onChange={(e: any) => {
											// setIsAllQuarter(false);
											setAllQtrColor('rgb(230, 0, 255)');
											let yearDifference =
												new Date().getFullYear() - Number(e.target.value);
											const newDate = new Date();
											const firstDateOfYear = new Date(
												newDate.getFullYear(),
												0,
												1
											);
											let lastYearDate: any = new Date(firstDateOfYear);
											lastYearDate.setFullYear(
												firstDateOfYear.getFullYear() - yearDifference
											);
											let yearLast1: any;
											if (yearDifference > 0) {
												yearLast1 = new Date(
													new Date().getFullYear() - 1,
													11,
													31
												);
											} else {
												yearLast1 = new Date(new Date().getFullYear(), 11, 31);
											}

											// yearLast1.setFullYear(firstDateOfYear.getFullYear());
											let newObject = {
												startDate: lastYearDate,
												endDate: yearLast1,
												key: 'selection1',
											};
											let tempDateRange = { ...dateRange };
											tempDateRange.selection1 = newObject;
											dateRangeObj = tempDateRange;
											setTimeout(() => {
												setDateRange(tempDateRange);
												setIsPreviousYear(true);
												setDateRangeNew(tempDateRange);
												setIsAllQuarter(true);
											}, 0);
											setSelectedYear(Number(e.target.value));
										}}
									/>
								</div>
							</>
						) : (
							<div
								onClick={() => {
									setAllQtrColor(theme.palette.color);
									setIsAllQuarter(false);
									setSelectedYear(new Date().getFullYear());
								}}
								style={{ fontSize: theme?.typography?.calendarFont }}
							>
								{range.label}
							</div>
						)}
					</div>
					{props.showQuatreOptions ? (
						<style>{`

			.rdrStaticRangeLabel:has(> div){
				padding: 0 !important;
			}
			.rdrStaticRange > .rdrStaticRangeLabel {
				font-size: ${theme?.typography?.calendarFont}
			}
		`}</style>
					) : (
						<>
							<style jsx>
								{`
									.rdrStaticRange > .rdrStaticRangeLabel {
										font-size: ${theme?.typography?.calendarFont};
									}
								`}
							</style>
						</>
					)}
				</>
			);
		} catch (error: any) {
			alert(error);
		}
	};
	const data = {
		fill: theme.palette.text.tertiary,
		width: '20px',
		height: '20px',
	};

	const handlingMonthandYear = (data: any) => {
		let originalDate = new Date(data);

		// Get the month name abbreviation
		let monthNames = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];
		let monthAbbreviation = monthNames[originalDate.getMonth()];

		// Get the year
		let year = originalDate.getFullYear();

		// Format the date as "Month, Year"
		let formattedDate = monthAbbreviation + ', ' + year;
		return formattedDate;
	};

	const checkWhichLabeltoSave = (data: any) => {
		let now = new Date();
		let duedate = new Date(now);
		const currentQuarterStart = moment().startOf('quarter');
		const currentQuarterEnd = moment().endOf('quarter');
		const year = moment().format('YYYY');

		const previousQuarterStart = moment()
			.startOf('quarter')
			.subtract(3, 'months');
		const previousQuarterEnd = moment().endOf('quarter').subtract(3, 'months');

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(firstDayOfWeek(new Date(), 0)) &&
			data.endDate == DateTimeFormatter.getDateInFormat(new Date())
		) {
			// --------------------------------This week-----------------------------------
			return `(${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		}

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(
					new Date(new Date().getFullYear(), new Date().getMonth(), 1)
				) &&
			data.endDate == DateTimeFormatter.getDateInFormat(new Date())
		) {
			//--------------------------This month--------------------------------
			return `(${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		}

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(startOfYear(new Date())) &&
			data.endDate == DateTimeFormatter.getDateInFormat(endOfDay(new Date()))
		) {
			//--------------------------This year--------------------------------
			return `(${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		}

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(
					startOfYear(addYears(new Date(), -1))
				) &&
			data.endDate ==
				DateTimeFormatter.getDateInFormat(endOfYear(addYears(new Date(), -1)))
		) {
			//--------------------------Last year--------------------------------
			return `(${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		}

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(
					new Date(now.getFullYear(), now.getMonth(), now.getDate() - 365)
				) &&
			data.endDate == DateTimeFormatter.getDateInFormat(new Date())
		) {
			//--------------------------Last 365 Days--------------------------------
			return `(${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		}

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(
					new Date(now.getFullYear(), now.getMonth(), now.getDate() - 730)
				) &&
			data.endDate == DateTimeFormatter.getDateInFormat(new Date())
		) {
			//--------------------------Last 2 Years--------------------------------
			return ` (${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		}

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(currentQuarterStart.toDate()) &&
			data.endDate ==
				DateTimeFormatter.getDateInFormat(currentQuarterEnd.toDate())
		) {
			//--------------------------Current Quarter--------------------------------
			return `(${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		}

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(previousQuarterStart.toDate()) &&
			data.endDate ==
				DateTimeFormatter.getDateInFormat(previousQuarterEnd.toDate())
		) {
			//--------------------------Previous Quarter--------------------------------
			return `(${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		}

		if (
			data.startDate ==
				DateTimeFormatter.getDateInFormat(
					moment(year, 'YYYY').startOf('year').toDate()
				) &&
			data.endDate ==
				DateTimeFormatter.getDateInFormat(
					moment(year, 'YYYY').endOf('year').toDate()
				)
		) {
			//--------------------------All Quarters or Current Year--------------------------------
			return `All Qtr (${DateTimeFormatter.getDateInFormat(
				data.startDate
			)} - ${DateTimeFormatter.getDateInFormat(data.endDate)})`;
		} else {
			return `${data.startDate + ' - ' + data.endDate}`;
		}
	};
	const useStyles: any = makeStyles((theme: any) => ({
		root: {
			'& .MuiFormControlLabel-label': {
				fontSize: theme?.typography?.calendarFont,
			},
		},
	}));
	const classes = useStyles();
	return (
		<>
			<div
				className={`ReactDateTimePicker ReactDateTimePicker__large`}
				style={theme.palette.reactFilterCalenderStyle}
			>
				<Grid
					container
					style={
						useLocalStorage.getItem('date_comparison') && isMultipleRange
							? {
									width: '16rem',
									fontSize: theme?.typography?.tabPerformance?.fontSize,
							  }
							: {
									width: 'fit-content',
									fontSize: theme?.typography?.breadCrum?.breadCrumFontSize,
							  }
					}
					className='ReactDateTimePicker__displayLabel'
					onClick={handleClick}
				>
					<Grid item xs={2}>
						<IconButton style={{ height: '100%' }}>
							{/* <img src={CalendarIcon} alt='Calendar Icon' /> */}
							<CalendarIcon {...data} />
						</IconButton>
					</Grid>
					<Grid item xs={10}>
						<span>
							{`${DateTimeFormatter.getDateInFormat(
								displayDate.startDate,
								format
							)} - ${DateTimeFormatter.getDateInFormat(
								displayDate.endDate,
								format
							)}`}
						</span>
						{useLocalStorage.getItem('date_comparison') && isMultipleRange ? (
							<Typography className='ReactDateTimePicker__compareRange'>
								Compare to:
								{` ${DateTimeFormatter.getDateInFormat(
									comparisionDisplayDate.startDate ||
										dateRange.selection2.startDate,
									format
								)} - ${DateTimeFormatter.getDateInFormat(
									comparisionDisplayDate.endDate ||
										dateRange.selection2.endDate,
									format
								)}`}
							</Typography>
						) : null}
					</Grid>
				</Grid>
				<Menu
					id='basic-menu'
					className='ReactDateTimePicker__menu'
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					classes={{
						root: 'ReactDateTimePicker__paper',
					}}
					// MenuListProps
				>
					{props.showCustomComponent ? (
						<div
							className={
								props.showQuatreOptions
									? 'ReactDateTimePicker__customComponentQuatre'
									: 'ReactDateTimePicker__customComponent ReactDateTimePicker__customComponent '
							}
							style={{
								position: 'absolute',
								top: storedFont !== 'large' ? '21rem' : '25rem',
								left: '20px',
							}}
						>
							<FormGroup className={`${classes?.root}`}>
								<FormControlLabel
									control={
										<Checkbox
											checked={isCompared}
											onChange={(evt: any) => {
												setIsCompared(evt.target.checked);
												setApplyButtonDisable(false);
											}}
											size='small'
										/>
									}
									label='Compare % change'
								/>
							</FormGroup>
						</div>
					) : null}
					{isMultipleRange ? (
						<div
							className={
								props.showQuatreOptions
									? 'ReactDateTimePicker__customComponentQuatre'
									: 'ReactDateTimePicker__customComponent ReactDateTimePicker__customComponent '
							}
							style={{
								position: 'absolute',
								top: storedFont !== 'large' ? '21rem' : '25rem',
								left: '20px',
							}}
						>
							<FormGroup className={`${classes?.root}`}>
								<FormControlLabel
									control={
										<Checkbox
											onChange={(evt: any) => {
												setApplyButtonDisable(false);
												setIsCompared(!isCompared);
											}}
											checked={isCompared}
											size='small'
										/>
									}
									label='Compare With'
								/>
							</FormGroup>
							<div
								hidden={!isCompared}
								style={{ marginTop: '1rem', width: '150px' }}
							>
								<Dropdown
									valueSelector='value'
									itemList={[
										{
											name: 'Same Period Previous Year',
											value: 3,
										},
										{
											name: 'Previous Period',
											value: 1,
										},
										{
											name: 'Previous Year',
											value: 4,
										},
										{
											name: 'Custom',
											value: 2,
										},
									]}
									label='name'
									selectedValue={selectedOption}
									name='Type'
									labelName='Select'
									onChange={(evt: any) => onOptionSelection(evt)}
								/>
							</div>
						</div>
					) : null}
					<DateRangePicker
						onChange={(item: any) => {
							if (
								item?.selection1 &&
								DateTimeFormatter.checkIfDateBefore(
									item.selection1.startDate,
									new Date(oneYear(props.minLastYear ? props.minLastYear : 2))
								)
							) {
								dateRange.selection1.startDate = new Date(
									oneYear(props.minLastYear ? props.minLastYear : 2)
								);
								dateRange.selection1.endDate = new Date();
							} else if (
								item?.selection1 &&
								DateTimeFormatter.checkIfDateBefore(
									item.selection1.endDate,
									new Date(oneYear(props.minLastYear ? props.minLastYear : 2))
								)
							) {
								dateRange.selection1.startDate = new Date(
									oneYear(props.minLastYear ? props.minLastYear : 2)
								);
								dateRange.selection1.endDate = new Date();
							} else if (
								item?.selection2 &&
								DateTimeFormatter.checkIfDateBefore(
									item.selection2.startDate,
									new Date(oneYear(props.minLastYear ? props.minLastYear : 2))
								)
							) {
								dateRange.selection2.startDate = new Date(
									oneYear(props.minLastYear ? props.minLastYear : 2)
								);
								dateRange.selection2.endDate = new Date();
							} else if (
								item?.selection2 &&
								DateTimeFormatter.checkIfDateBefore(
									item.selection2.startDate,
									new Date(oneYear(props.minLastYear ? props.minLastYear : 2))
								)
							) {
								dateRange.selection2.startDate = new Date(
									oneYear(props.minLastYear ? props.minLastYear : 2)
								);
								dateRange.selection2.endDate = new Date();
							} else {
								dateRange = { ...dateRange, ...item };
							}
							setApplyButtonDisable(false);
							setDateRange({ ...dateRange });
							setSelectedOption(2);
						}}
						editableDateInputs
						maxDate={new Date()}
						minDate={
							new Date(oneYear(props.minLastYear ? props.minLastYear : 3))
						}
						moveRangeOnFirstSelection={false}
						months={isMultipleRange ? 2 : 1}
						renderStaticRangeLabel={handleRanges}
						direction='horizontal'
						rangeColors={[
							theme.palette.text.fourth,
							theme.palette.text.tertiary,
						]}
						staticRanges={customRanges}
						startDatePlaceholder={'Start Date'}
						endDatePlaceholder={'End Date'}
						ranges={
							// showComparison ?
							isCompared == true && props?.showComparison
								? [dateRange.selection1, dateRange.selection2]
								: [dateRange.selection1]
						}
						inputRanges={inputRange}
						className={
							theme.palette.mode === 'dark'
								? 'ReactFilterDateRangePickerDark'
								: 'ReactFilterDateRangePicker'
						}
					/>
					<div>
						<div className='ReactDateTimePicker__button'>
							<Button
								btnText='Cancel'
								type='button'
								variant='outlined'
								onClick={handleClose}
							/>
							<Button
								btnText='Apply'
								type='button'
								disable={applyButtonDisable}
								onClick={(evt: any) => {
									saveDateForLabel = {
										startDate: DateTimeFormatter.getDateInFormat(
											dateRange.selection1.startDate
										),
										endDate: DateTimeFormatter.getDateInFormat(
											dateRange.selection1.endDate
										),
									};
									setSaveDateforLabel(saveDateForLabel);
									let DateRangeLabel = checkWhichLabeltoSave(saveDateForLabel);
									useLocalStorage.setItem('dateRangeLabel', DateRangeLabel);
									setIsDateApplied(true);
									useLocalStorage.setItem('date_comparison', isCompared);
									!props.showComparison ? '' : props?.onCompareChange(evt);
									!props.showCustomComponent ? '' : props?.onCompareChange(evt);

									if (isMultipleRange) {
										props?.onChange(dateRange, isAllQuarter);
									} else {
										props?.onChange(
											{
												startDate: DateTimeFormatter.getDateInFormat(
													dateRange.selection1.startDate
												),
												endDate: DateTimeFormatter.getDateInFormat(
													dateRange.selection1.endDate
												),
											},
											isAllQuarter
										);
									}
									if (
										!(
											location.search &&
											location.search?.split('=')[0].includes('?bookmarkId')
										)
									) {
										useLocalStorage.setItem('dateRange', {
											start_date: dateRange.selection1.startDate,
											end_date: dateRange.selection1.endDate,
											isAllQuarter: isAllQuarter,
											selectedYear: selectedYear,
										});
										if (props?.showQuatreOptions) {
											props.isShowQuarter(isAllQuarter);
										}
										if (isMultipleRange) {
											useLocalStorage.setItem('compdateRange', {
												pre_start_date: dateRange.selection2.startDate,
												pre_end_date: dateRange.selection2.endDate,
											});
										}
									}
									if (isMultipleRange) {
										setComparisonDisplayDate({ ...dateRange.selection2 });
										useLocalStorage.setItem('timePeriod', selectedOption);
									}

									setDisplayDate({ ...dateRange.selection1 });
									setApplyButtonDisable(true);
									// handleClose();
								}}
							/>
						</div>
					</div>
				</Menu>
			</div>
			<style jsx>
				{`
					.rdrMonth span {
						font-size: ${theme?.typography?.calendarFont};
					}
					.rdrMonthName {
						font-size: ${theme?.typography?.calendarFont};
					}
					.rdrMonthAndYearPickers {
						font-size: ${theme?.typography?.calendarFont};
					}
					.ReactFilterDateRangePickerDark .rdrDateDisplayItem input {
						font-size: ${theme?.typography?.calendarFont};
					}
				`}
			</style>
		</>
	);
	function oneYear(num: any, date = new Date()) {
		date.setFullYear(date.getFullYear() - num);
		return date;
	}
};

export default ReactDatePicker;
