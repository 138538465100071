import {
	Box,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import Button from '../../components/Button/Button';
import { Download, VerticalAlignBottomOutlined } from '@mui/icons-material';
import useLocalStorage from '../../utils/localStorage';
import useApiService from '../../services/api.service';
import ShareReportsEndpoints from './ShareReportsEndpoints';
import { savePDF } from '@progress/kendo-react-pdf';
import React, { createElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/styles';
import pageTemplate from './pageTemplate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function ShareThroughDownload({
	dateRange,
	filters,
	toaster,
	loader,
	name,
	id,
	ref,
	shareReportsRequest,
	closePopup,
	downloadPDFClassName,
}: any) {
	const includeCSV = [
		'CMO',
		'Performance',
		'Channel Performance',
		'Paid Campaign Tracker',
		'Paid Media Tracker',
		'SEO',
		'Email Marketing',
		'Total Spend',
	];
	const theme: any = useTheme;
	const APIService: any = useApiService();
	const userData: any = useLocalStorage.getItem('userData');
	const [values, setValues] = React.useState('CSV');

	const handleCSV = (link: any) => {
		let tempLink = document.createElement('a');
		tempLink.href = link;
		tempLink.click();
	};

	const handleCSVDownload = async () => {
		const headers: any = { 'access-token': userData['access-token'] };
		const request: any = {};
		request.linking_type = 0;
		request.format_key = 0;
		request.dashboard_id = id;
		request.report_name = name;
		request.payload = { ...shareReportsRequest };
		const reqBody: any = {
			headers: headers,
			request: request,
		};
		loader.showLoader();
		await APIService.post(ShareReportsEndpoints.downloadCSV(reqBody))
			.then((res: any) => {
				if (name == 'Performance') {
					res?.data?.forEach((data: any, index: number) => {
						setTimeout(() => {
							handleCSV(data);
						}, 1000 * (index + 1));
					});
				} else {
					const tempLink = document.createElement('a');
					tempLink.href = res.data;
					tempLink.click();
				}
			})
			.catch((err: any) => {
				toaster.addToast({
					type: 'error',
					message: err.message,
					timeout: 3000,
				});
			})
			.finally(() => {
				loader.hideLoader();
			});
	};

	const downloadPDFforReports = async () => {
		let gridElement: any = document.getElementsByClassName(
			`${downloadPDFClassName}`
		)[0];
		loader.showLoader();
		savePDF(gridElement, {
			paperSize: 'A4',
			scale:
				name == 'Performance' ||
				name == 'Social Media' ||
				// name == 'CMO' ||
				name == 'Sales Cycle'
					? 0.4
					: 0.3,
			title: `${name} Report`,
			repeatHeaders: false,
			fileName: name,
			landscape: false,
			margin: 0,
			forcePageBreak: '.page-break',
			keepTogether: '.keep-together',
			pageTemplate: pageTemplate,
		});
		setTimeout(() => {
			loader.hideLoader();
		}, 2000);
	};

	const scrollPage = () => {
		// window.scrollTo(100, 5000);
		// const element = document.getElementsByClassName('marginForFixedHeading')[0];
		// element.scrollTop = 800;
	};

	function generatePDFFromBlobs(blobs: Blob[], pageWidth: number) {
		// Create a new jsPDF instance
		var pdf = new jsPDF({
			unit: 'px',
			format: [pageWidth, 841], // Standard A4 height
		});
		// Define constants for positioning and dimensions
		const margin = 0; // Set margin to 0 for no margins
		// const pageWidth = 1 * pdf.internal.pageSize.getWidth(); // Double the page width

		// Function to add image to PDF
		function addImageToPDF(base64data: string, index: number) {
			return new Promise<void>((resolve, reject) => {
				var img = new Image();
				img.onload = function () {
					// Set the width and height to match the page dimensions
					const width = pageWidth - 1 * margin; // Adjusted width
					const height = pdf.internal.pageSize.getHeight() * margin; // Use original height
					// Add image to PDF, positioned at the margins
					pdf.addImage(base64data, 'PNG', margin, margin, width, height);
					if (index < blobs.length - 1) {
						pdf.addPage(); // Add a new page for each image except the last one
					} else {
						// Save the PDF once all images are added
						pdf.save('screenshots.pdf');
					}
					resolve();
				};
				img.onerror = function () {
					reject(new Error('Failed to load image.'));
				};
				img.src = base64data;
			});
		}

		// Loop through each blob and add it as an image to the PDF
		blobs.forEach((blob, index) => {
			var reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = function () {
				var base64data = reader.result as string;
				addImageToPDF(base64data, index);
			};
		});
	}

	function getBlobs(screenshots: HTMLCanvasElement[]) {
		return screenshots.map(function (screenshot) {
			var dataURI = screenshot.toDataURL();

			// convert base64 to raw binary data held in a string
			// doesn't handle URLEncoded DataURIs
			var byteString = atob(dataURI.split(',')[1]);

			// separate out the mime component
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

			// write the bytes of the string to an ArrayBuffer
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			// create a blob for writing to a file
			var blob = new Blob([ab], { type: mimeString });
			return blob;
		});
	}

	// async function capturePage() {
	// 	closePopup();
	// 	const scrollArea = document.getElementsByClassName(
	// 		'marginForFixedHeading'
	// 	)[0] as HTMLElement;
	// 	const totalHeight = scrollArea.scrollHeight;
	// 	const totalWidth = 816; //scrollArea.scrollWidth;
	// 	const viewportHeight = 1056; //window.innerHeight;
	// 	const numScreenshots = Math.ceil(totalHeight / viewportHeight);
	// 	console.log(numScreenshots);
	// 	// we do not need these as of now ---------------------
	// 	const container = document.createElement('div'); // Create a container element to hold the screenshots
	// 	container.classList.add('screenshot-container'); // Add a class for styling if needed
	// 	//------------------------------------------------------

	// 	const pageWidth = 850; // Set a wider page width
	// 	const scale = 1.2;

	// 	let tempArray: HTMLCanvasElement[] = [];
	// 	for (let i = 0; i < numScreenshots; i++) {
	// 		const ele = document.getElementsByClassName(
	// 			'marginForFixedHeading'
	// 		)[0] as HTMLElement;
	// 		ele.scrollTop = i * viewportHeight;
	// 		await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for scrolling to finish
	// 		const screenshot = await html2canvas(scrollArea, {
	// 			scale: scale, // Adjust scale for higher resolution
	// 			logging: false, // Disable logging to improve performance
	// 		});
	// 		// const canvas = document.createElement('canvas');
	// 		// canvas.width = totalWidth;
	// 		// canvas.height = totalHeight; // Adjust canvas height to cover entire content

	// 		// const ctx: any = canvas.getContext('2d');

	// 		// ctx.drawImage(screenshot, 0, i * viewportHeight);
	// 		tempArray.push(screenshot);
	// 	}
	// 	getBlobs(tempArray), pageWidth;
	// 	// combineBlobsToPdf(getBlobs(tempArray))
	// 	// generatePDFFromBlobs(getBlobs(tempArray), pageWidth);
	// 	// initEntireCapture()
	// }

	// window.jsPDF = window.jspdf.jsPDF;
	function generatePdf() {
		let jsPdf = new jsPDF('p', 'pt', 'letter');
		var htmlElement: any = document.getElementsByClassName(
			'marginForFixedHeading'
		)[0];
		// you need to load html2canvas (and dompurify if you pass a string to html)
		const opt: any = {
			callback: function (jsPdf: any) {
				jsPdf.save('Test.pdf');
				// to open the generated PDF in browser window
				// window.open(jsPdf.output('bloburl'));
			},
			margin: [1, 1, 1, 1],
			autoPaging: 'text',
			html2canvas: {
				allowTaint: true,
				dpi: 300,
				letterRendering: true,
				logging: false,
				scale: 1,
				width: 850,
				height: 841,
			},
		};

		jsPdf.html(htmlElement, opt);
	}

	function PrintAll(screenshots: any) {
		var pages: any = [screenshots];

		var printNext = function (i?: any) {
			i = i || 0;
			if (i >= pages.length) {
				return;
			}

			var wdw: any = window.open(pages[i], 'print');
			wdw.onload = function () {
				wdw.print();

				wdw.close();
				setTimeout(function () {
					printNext(++i);
				}, 100);
			};
		};

		printNext();
	}

	function createPdf() {
		// capturePage
		closePopup();
		setTimeout(() => {
			var saveInnerHTML = document.body.innerHTML;
			var actContents = document.getElementsByClassName(
				'marginForFixedHeading'
			)[0].innerHTML;
			document.body.innerHTML = actContents;
			window.print();
			document.body.innerHTML = saveInnerHTML;
			// PrintAll()
		}, 1000);
	}

	function printContent() {
		closePopup();
		// capturePage();
		var currentUrl = window.location.href;
		var pdfUrl;

		if (currentUrl.includes('app.diggrowth.com')) {
			pdfUrl = currentUrl.replace(
				'/insights/mo/metrics/hub',
				'/insights/mo/metrics/hub/pdf'
			);
		} else if (currentUrl.includes('test.digg.ai')) {
			pdfUrl = currentUrl.replace(
				'/insights/mo/metrics/hub',
				'/insights/mo/metrics/hub/pdf'
			);
		} else if (currentUrl.includes('beta.diggrowth.com')) {
			pdfUrl = currentUrl.replace(
				'/insights/mo/metrics/hub',
				'/insights/mo/metrics/hub/pdf'
			);
		} else {
			pdfUrl = currentUrl.replace(
				'/insights/mo/metrics/hub',
				'/insights/mo/metrics/hub/pdf'
			);
		}

		// Open the PDF URL in a new tab
		if (pdfUrl) {
			window.open(pdfUrl);
		} else {
			console.error('Unsupported URL:', currentUrl);
		}
	}

	function checkReport() {
		var currentUrl = window.location.href;
		if (currentUrl.includes('/metrics/hub')) {
			printContent();
		} else {
			downloadPDFforReports();
		}
	}

	return (
		<Grid xs={12} container className='shareByDownload'>
			<div id='myMm' style={{ height: '1mm' }} />
			<iframe id='printFrame' style={{ display: 'none' }}></iframe>
			<form style={{ width: '100%' }} className='shareByEmail'>
				{/* <Grid xs={12} className='shareByEmail__radio'>
					<RadioGroup
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={value}
						style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
						onChange={handleChange}
					>
						<Grid xs={6}>
							{' '}
							<FormControlLabel
								value='all_reports'
								control={<Radio size='small' />}
								label='All Reports'
							/>
						</Grid>
						<Grid xs={6}>
							<FormControlLabel
								value='current_report'
								control={<Radio size='small' />}
								label='Current Report'
							/>
						</Grid>
					</RadioGroup>
				</Grid> */}

				<Grid xs={10} display={'flex'} gap={'20px'}>
					<div
						className='downloadButton'
						style={{ border: `1px solid #bebebe`, cursor: 'pointer' }}
						onClick={() => {
							checkReport();
						}}
					>
						<VerticalAlignBottomOutlined color='primary' />
						<div>PDF</div>
					</div>
					{/* <Button
						variant='outlined'
						type='button'
						btnText='PDF'
						startIcon={<Download />}
						onClick={() => {
							downloadPDFforReports();
						}}
					/> */}
					{includeCSV.includes(name) && (
						<div
							className='downloadButton'
							style={{ border: `1px solid #bebebe`, cursor: 'pointer' }}
							onClick={() => {
								handleCSVDownload();
							}}
						>
							<VerticalAlignBottomOutlined color='primary' />
							<div>CSV</div>
						</div>
					)}
				</Grid>
				<Grid
					xs={12}
					textAlign={'center'}
					display={'flex'}
					alignItems={'end'}
					justifyContent={'center'}
				>
					{/* <Button
						type='submit'
						btnText='Download Report'
						style={{ width: '30%' }}
					/> */}

					<Button
						variant='outlined'
						type='button'
						btnText='Cancel'
						style={{ width: '30%' }}
						onClick={() => {
							closePopup();
						}}
					/>
				</Grid>
			</form>
		</Grid>
	);
}

export default ShareThroughDownload;
