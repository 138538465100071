import {
	SET_CMO_CLOSED_WON_DATA,
	SET_CMO_INSIGHTS_TABLE_DATA,
	SET_CMO_PIPELINE_API_DATA,
	SET_CMO_REQUEST_DATA,
	SET_CMO_GRAPH_DATA,
	SET_CMO_REQUEST_BODY,
	SET_CMO_BOOKMARK_REQUEST_BODY_DATA,
	SET_CMO_METRIC_VALUE,
	SET_CMO_DIMENSION_VALUE,
	RESET_TO_INTIAL_STATE,
} from '../actionTypes/actionType';
import formatNumber from '../utils/metricSuffix';
import { colors } from '../containers/CMODashboard/CmoDashboardConstants';
import twoDecimal from '../utils/decimalPercentage';
import { monthsArray } from '../containers/Reports/Dashboard/InsightsData';
import { monthDiff } from '../containers/Reports/analytic-report-trend/analytic-report-data';
import { InsightsData } from '../containers/Reports/Dashboard/InsightsData';
const intinalState = {
	cmo_closed_won: {
		roi_cards_data: null,
		cards_data: null,
		closed_won_card_data: null,
	},
	average_sale_cycle_data: null,
	average_deal_cycle_data: null,
	cmo_pipeline_data: {
		roi_cards_data: null,
		donut_data: null,
		box_data: null,
	},
	cmo_insights_table_data: null,
	cmo_request_body: {
		date_range: InsightsData.getDefaultRange(),
		filter_data: InsightsData.getDefaultFilters(),
	},
	cmo_bookmark_request_body: null,
	cmo_metric_value: 'spend',
	cmo_dimension_value: 'channel',
};
let requestData = {};

export const cmoDashboardReducer = (state = intinalState, action) => {
	function getClosedWonData(data) {
		return {
			closed_won: {
				ROI: [...data?.mrkt_roi_grafh],
				CPO: [...data?.mrkt_cpo_grafh],
				ROI_change: data?.mi_roi_comp + data?.ms_roi_comp,
				CPO_change: data?.mi_cpo_comp + data?.ms_cpo_comp,
				value: [
					`${(data?.mi_roi + data?.ms_roi).toFixed(2)}x`,
					`$${formatNumber(data?.mi_cpo + data?.ms_cpo)}`,
				],
				change: [
					data?.mi_roi_comp + data?.ms_roi_comp,
					data?.mi_cpo_comp + data?.ms_cpo_comp,
				],
				change2: [
					data?.mi_roas_comp + data?.ms_roas_comp,
					data?.mi_cpo_comp + data?.ms_cpo_comp,
				],
				ROAS_change: data?.mi_roas_comp + data?.ms_roas_comp,
				ROAS: [...data?.mrkt_roas_grafh],
				ROAS_value: [
					`${(data?.mi_roas + data?.ms_roas).toFixed(2)}x`,
					`$${formatNumber(data?.mi_cpo + data?.ms_cpo)}`,
				],
			},
		};
	}

	function getCardsData(data) {
		const list = [];
		let dataToReturn = {
			donutData: null,
			cardList: [],
		};
		if (data?.config?.length) {
			data.config.map((item, index) => {
				if (item.name !== 'default') {
					list.push({
						name: item.name,
						y: item.revenue,
						color:
							data?.config?.length == 2 ? colors[index + 1] : colors[index],
					});
				}
				if (item.name == 'Sales' || item.name == 'Others') {
					dataToReturn.cardList.push({
						name: item.name,
						total: item.per_total,
						total_change: item.per_total_comp,
						oppts: item?.total_oppr,
						oppts_change: item?.total_oppr_comp,
						color:
							data?.config?.length == 2 ? colors[index + 1] : colors[index],
						revenue: item?.revenue_comp,
						revenue_value: item?.revenue,
					});
				}
			});
			dataToReturn.donutData = [
				{
					name: 'Marketing Influenced',
					y: data.mrkt_influenced,
					color: 'red',
				},
				{ name: 'Marketing Sourced', y: data.mrkt_sourced, color: 'blue' },
				...list,
			];
		}
		return dataToReturn;
	}

	function getAverageSaleCycleData(data) {
		return {
			overall: {
				value: data?.avg_sale_cycle?.overall
					? data?.avg_sale_cycle?.overall
					: 0,
				change: twoDecimal(data?.avg_sale_cycle?.overall_comp) || 0,
			},
			influenced: {
				value: data?.avg_sale_cycle?.mrkt_influenced
					? data?.avg_sale_cycle?.mrkt_influenced
					: 0,
				change: twoDecimal(data?.avg_sale_cycle?.mrkt_influenced_comp) || 0,
			},
			sourced: {
				value: data.avg_sale_cycle?.mrkt_sourced
					? data?.avg_sale_cycle?.mrkt_sourced
					: 0,
				change: twoDecimal(data.avg_sale_cycle?.mrkt_sourced_comp) || 0,
			},
		};
	}

	function getAverageDealCycleData(data) {
		return {
			overall: {
				value: data?.avg_deal_size?.overall
					? formatNumber(data?.avg_deal_size?.overall)
					: 0,
				change: twoDecimal(data?.avg_deal_size?.overall_comp) || 0,
			},
			influenced: {
				value: data?.avg_deal_size?.mrkt_influenced
					? formatNumber(data?.avg_deal_size?.mrkt_influenced)
					: 0,
				change: twoDecimal(data?.avg_deal_size?.mrkt_influenced_comp) || 0,
			},
			sourced: {
				value: data?.avg_deal_size?.mrkt_sourced
					? formatNumber(data?.avg_deal_size?.mrkt_sourced)
					: 0,
				change: twoDecimal(data?.avg_deal_size?.mrkt_sourced_comp) || 0,
			},
		};
	}

	function getClosedWonCardData(data) {
		return {
			box_values: [
				{
					name: 'Total Closed Won',
					value: data?.closed_won?.total_closed_won || 0,
					change: twoDecimal(data?.closed_won?.total_closed_won_comp) || 0,
				},
				{
					name: 'Avg. Size (Overall)',
					value: data?.closed_won?.avg_size_overall || 0,
					change: twoDecimal(data?.closed_won?.avg_size_overall_comp) || 0,
				},
				{
					name: 'Marketing Spent',
					value: data?.closed_won?.marketing_spend || 0,
					change: twoDecimal(data?.closed_won?.marketing_spend_comp) || 0,
				},
			],
			venn_data: [
				{
					name: 'Total Revenue',
					value: data?.closed_won?.total_revenue || 0,
					change: twoDecimal(data?.closed_won?.total_revenue_comp) || 0,
				},
				{
					name: 'Marketing Influenced',
					value: data?.closed_won?.mrkt_influenced || 0,
					change: twoDecimal(data?.closed_won?.mrkt_influenced_comp) || 0,
				},
				{
					name: 'Marketing Sourced',
					value:
						data &&
						data.closed_won &&
						data.closed_won.mrkt_sourced &&
						data.closed_won.mrkt_sourced !== 0
							? `${data.closed_won.mrkt_sourced}`
							: 0,
					change: twoDecimal(data?.closed_won?.mrkt_sourced_comp) || 0,
				},
			],
			summary: [
				{
					total: {
						value: data?.closed_won?.mi_per_total,
						change: twoDecimal(data?.closed_won?.mi_per_total_comp) || 0,
					},
					roi: {
						value: data?.closed_won?.mi_roi,
						change: twoDecimal(data?.closed_won?.mi_roi_comp) || 0,
					},
					oppts: {
						value: data?.closed_won?.mrkt_influenced_oppr,
						change:
							twoDecimal(data?.closed_won?.mrkt_influenced_oppr_comp) || 0,
					},
					cpo: {
						value: data?.closed_won?.mi_cpo,
						change: twoDecimal(data?.closed_won?.mi_cpo_comp) || 0,
					},
					options: {
						label: 'Marketing Influenced',
						color: '#9149FF',
					},
				},
				{
					total: {
						value: data?.closed_won?.ms_per_total,
						change: data?.closed_won?.ms_per_total_comp || 0,
					},
					roi: {
						value: data?.closed_won?.ms_roi,
						change: data?.closed_won?.ms_roi_comp || 0,
					},
					oppts: {
						value: data?.closed_won?.mrkt_sourced_oppr,
						change: data?.closed_won?.mrkt_sourced_oppr_comp || 0,
					},
					cpo: {
						value: data?.closed_won?.ms_cpo,
						change: data?.closed_won?.ms_cpo_comp || 0,
					},
					options: {
						label: 'Marketing Sourced',
						color: '#133463',
					},
				},
			],
		};
	}

	function getCmoGraphData(data, startDate, endDate) {
		if (data.length == 0) {
			return null;
		}
		let seriesLabels = [],
			seriesData = [];
		data.map((item) => {
			const isLabelFound = seriesLabels.includes(
				item.date_string.replace(',', ' ')
			);
			if (!isLabelFound) {
				seriesLabels.push(item.date_string.replace(',', ' '));
			}
		});
		if (seriesLabels.length == 0) {
			seriesLabels = monthsArray;
		}
		// const diffrence =
		// 	monthDiff(
		// 		new Date(InsightsData.getDefaultRange().start_date),
		// 		new Date(InsightsData.getDefaultRange().end_date)
		// 	) + 1;
		const diffrence = monthDiff(new Date(startDate), new Date(endDate)) + 1;
		data.map((item) => {
			const yAxisValues = new Array(diffrence).fill(0);
			const foundedIndex = seriesLabels.findIndex(
				(seriesLabelItem) =>
					item.date_string.replace(',', ' ') === seriesLabelItem
			);
			yAxisValues[foundedIndex] += item.value;
			let isKeywordFound = false;
			seriesData.find((keywordName, index) => {
				if (keywordName?.name == item.name) {
					seriesData[index].data[foundedIndex] += item.value;
					isKeywordFound = true;
				}
			});
			if (!isKeywordFound && item.name?.trim()) {
				seriesData.push({
					name: item.name,
					data: [...yAxisValues],
				});
			}
		});
		return {
			seriesLabels: seriesLabels,
			seriesData: seriesData,
		};
	}

	function getCmoPipelineData(data) {
		return {
			total_pipeline: {
				ROI: [...data?.total_pipeline?.mrkt_roi_grafh],
				CPO: [...data?.total_pipeline?.mrkt_cpo_grafh],
				ROI_change:
					data?.total_pipeline.mi_roi_comp + data?.total_pipeline.ms_roi_comp,
				ROAS_change:
					data?.total_pipeline.mi_roas_comp + data?.total_pipeline.ms_roas_comp,
				CPO_change:
					data?.total_pipeline.mi_cpo_comp + data?.total_pipeline.ms_cpo_comp,
				value: [
					`${(
						data?.total_pipeline?.mi_roi + data?.total_pipeline?.ms_roi
					).toFixed(2)}x`,
					`$${formatNumber(
						data?.total_pipeline?.mi_cpo + data?.total_pipeline?.ms_cpo
					)}`,
				],
				change: [
					data?.total_pipeline.mi_roi_comp + data?.total_pipeline.ms_roi_comp,
					data?.total_pipeline.mi_cpo_comp + data?.total_pipeline.ms_cpo_comp,
				],
				change2: [
					data?.total_pipeline.mi_roas_comp + data?.total_pipeline.ms_roas_comp,
					data?.total_pipeline.mi_cpo_comp + data?.total_pipeline.ms_cpo_comp,
				],

				ROAS: [...data?.total_pipeline?.mrkt_roas_grafh],
				ROAS_value: [
					`${(
						data?.total_pipeline?.mi_roas + data?.total_pipeline?.ms_roas
					).toFixed(2)}x`,
					`$${formatNumber(
						data?.total_pipeline?.mi_cpo + data?.total_pipeline?.ms_cpo
					)}`,
				],
			},
			current_pipeline: {
				ROI: [...data?.current_pipeline?.mrkt_roi_grafh],
				CPO: [...data?.current_pipeline?.mrkt_cpo_grafh],
				ROI_change:
					data?.current_pipeline.mi_roi_comp +
					data?.current_pipeline.ms_roi_comp,
				ROAS_change:
					data?.current_pipeline?.mi_roas_comp +
					data?.current_pipeline?.ms_roas_comp,
				CPO_change:
					data?.current_pipeline.mi_cpo_comp +
					data?.current_pipeline.ms_cpo_comp,
				value: [
					`${(
						data?.current_pipeline?.mi_roi + data?.current_pipeline?.ms_roi
					).toFixed(2)}x`,
					`$${formatNumber(
						data?.current_pipeline?.mi_cpo + data?.current_pipeline?.ms_cpo
					)}`,
				],
				change: [
					data?.current_pipeline?.mi_roi_comp +
						data?.current_pipeline?.ms_roi_comp,
					data?.current_pipeline?.mi_cpo_comp +
						data?.current_pipeline?.ms_cpo_comp,
				],
				change2: [
					data?.current_pipeline?.mi_roas_comp +
						data?.current_pipeline?.ms_roas_comp,
					data?.current_pipeline?.mi_cpo_comp +
						data?.current_pipeline?.ms_cpo_comp,
				],
				ROAS: [...data?.current_pipeline?.mrkt_roas_grafh],
				ROAS_value: [
					`${(
						data?.current_pipeline?.mi_roas + data?.current_pipeline?.ms_roas
					).toFixed(2)}x`,
					`$${formatNumber(
						data?.current_pipeline?.mi_cpo + data?.current_pipeline?.ms_cpo
					)}`,
				],
			},
		};
	}

	function getCmoPipleineDonutData(data) {
		const cardList_total_pipeline = [];
		const cardList_current_pipeline = [];
		const total_list = [];
		const current_list = [];
		let dataToReturn = {
			configCards: null,
			totalPipelineDonutData: null,
			currentPipelineDonutData: null,
		};
		data?.total_pipeline?.config.map((item, index) => {
			if (item.name !== 'default') {
				total_list.push({
					name: item.name,
					y: item.revenue,
					color:
						data?.total_pipeline?.config?.length == 2
							? colors[index + 1]
							: colors[index],
				});
			}
			if (item.name == 'Sales' || item.name == 'Others') {
				cardList_total_pipeline.push({
					name: item.name,
					total: item.per_total,
					total_change: item.per_total_comp,
					oppts: item?.total_oppr,
					oppts_change: item?.total_oppr_comp,
					color:
						data?.total_pipeline?.config?.length == 2
							? colors[index + 1]
							: colors[index],
					revenue: item?.revenue_comp,
					revenue_value: item?.revenue,
				});
			}
		});
		dataToReturn.configCards = { total_pipeline: cardList_total_pipeline };
		let total_reqArr = [];
		total_reqArr =
			data?.total_pipeline?.config?.length == 1
				? data?.total_pipeline?.config
				: data?.total_pipeline?.config.slice(1);
		dataToReturn.totalPipelineDonutData = [
			{
				name: 'Marketing Influenced',
				y: data.total_pipeline.mrkt_influenced,
				color: '#9149FF',
			},
			{
				name: 'Marketing Sourced',
				y: data.total_pipeline.mrkt_sourced,
				color: '#133463',
			},
			...total_list,
		];
		data?.current_pipeline?.config.map((item, index) => {
			if (item.name !== 'default') {
				current_list.push({
					name: item.name,
					y: item.revenue,
					color:
						data?.current_pipeline?.config?.length == 2
							? colors[index + 1]
							: colors[index],
				});
			}

			if (item.name == 'Sales' || item.name == 'Others') {
				cardList_current_pipeline.push({
					name: item.name,
					total: item.per_total,
					total_change: item.per_total_comp,
					oppts: item?.total_oppr,
					oppts_change: item?.total_oppr_comp,
					color:
						data?.current_pipeline?.config?.length == 2
							? colors[index + 1]
							: colors[index],
					revenue: item?.revenue_comp,
					revenue_value: item?.revenue,
				});
			}
		});
		dataToReturn.configCards = {
			...dataToReturn.configCards,
			current_pipeline: cardList_current_pipeline,
		};
		let current_reqArr = [];
		current_reqArr =
			data?.current_pipeline?.config.length == 1
				? data?.current_pipeline?.config
				: data?.current_pipeline?.config.slice(1);
		dataToReturn.currentPipelineDonutData = [
			{
				name: 'Marketing Influenced',
				y: data.current_pipeline.mrkt_influenced,
				color: '#9149FF',
			},
			{
				name: 'Marketing Sourced',
				y: data.current_pipeline.mrkt_sourced,
				color: '#133463',
			},
			...current_list,
		];
		return dataToReturn;
	}

	function getCmoPipelineBoxData(res) {
		return {
			currentPipelineData: {
				box_values: [
					{
						name: 'Total Opportunities',
						value: res?.current_pipeline?.total_oppr || 0,
						change: twoDecimal(res?.current_pipeline?.total_oppr_comp) || 0,
					},
					{
						name: 'Avg. Size (Overall)',
						value: res?.current_pipeline?.avg_size_overall || 0,
						change:
							twoDecimal(res?.current_pipeline?.avg_size_overall_comp) || 0,
					},
				],
				venn_data: [
					{
						name: 'Total Pipeline',
						value: res?.current_pipeline?.total_pipeline || 0,
						change: twoDecimal(res?.current_pipeline?.total_pipeline_comp) || 0,
					},
					{
						name: 'Marketing Influenced',
						value: res?.current_pipeline?.mrkt_influenced || 0,
						change:
							twoDecimal(res?.current_pipeline?.mrkt_influenced_comp) || 0,
					},
					{
						name: 'Marketing Sourced',
						value: res?.current_pipeline?.mrkt_sourced || 0,
						change: twoDecimal(res?.current_pipeline?.mrkt_sourced_comp) || 0,
					},
				],
				summary: [
					{
						total: {
							value: res?.current_pipeline?.mi_per_total,
							change: res?.current_pipeline?.mi_per_total_comp || 0,
						},
						oppts: {
							value: res?.current_pipeline?.mrkt_influenced_oppr,
							change: res?.current_pipeline?.mrkt_influenced_oppr_comp || 0,
						},
						options: {
							label: 'Marketing Influenced',
							color: '#9149FF',
						},
					},
					{
						total: {
							value: res?.current_pipeline?.ms_per_total,
							change: res?.current_pipeline?.ms_per_total_comp || 0,
						},
						oppts: {
							value: res?.current_pipeline?.mrkt_sourced_oppr,
							change: res?.current_pipeline?.mrkt_sourced_oppr_comp || 0,
						},
						options: {
							label: 'Marketing Sourced',
							color: '#133463',
						},
					},
				],
			},
			totalPipelineData: {
				box_values: [
					{
						name: 'Total Opportunities',
						value: res?.total_pipeline?.total_oppr || 0,
						change: twoDecimal(res?.total_pipeline?.total_oppr_comp) || 0,
					},
					{
						name: 'Avg. Size (Overall)',
						value: res?.total_pipeline?.avg_size_overall || 0,
						change: twoDecimal(res?.total_pipeline?.avg_size_overall_comp) || 0,
					},
				],
				venn_data: [
					{
						name: 'Total Pipeline',
						value: res?.total_pipeline?.total_pipeline || 0,
						change: twoDecimal(res?.total_pipeline?.total_pipeline_comp) || 0,
					},
					{
						name: 'Marketing Influenced',
						value: res?.total_pipeline?.mrkt_influenced || 0,
						change: twoDecimal(res?.total_pipeline?.mrkt_influenced_comp) || 0,
					},
					{
						name: 'Marketing Sourced',
						value: res?.total_pipeline?.mrkt_sourced || 0,
						change: twoDecimal(res?.total_pipeline?.mrkt_sourced_comp) || 0,
					},
				],
				summary: [
					{
						total: {
							value: res?.total_pipeline?.mi_per_total,
							change: res?.total_pipeline?.mi_per_total_comp || 0,
						},
						roi: {
							value: res?.total_pipeline?.mi_roi,
							change: res?.total_pipeline?.mi_roi_comp || 0,
						},
						oppts: {
							value: res?.total_pipeline?.mrkt_influenced_oppr,
							change: res?.total_pipeline?.mrkt_influenced_oppr_comp || 0,
						},
						cpo: {
							value: res?.total_pipeline?.mi_cpo,
							change: res?.total_pipeline?.mi_cpo_comp || 0,
						},
						options: {
							label: 'Marketing Influenced',
							color: '#9149FF',
						},
					},
					{
						total: {
							value: res?.total_pipeline?.ms_per_total,
							change: res?.total_pipeline?.ms_per_total_comp || 0,
						},
						roi: {
							value: res?.total_pipeline?.ms_roi,
							change: res?.total_pipeline?.ms_roi_comp || 0,
						},
						oppts: {
							value: res?.total_pipeline?.mrkt_sourced_oppr,
							change: res?.total_pipeline?.mrkt_sourced_oppr_comp || 0,
						},
						cpo: {
							value: res?.total_pipeline?.ms_cpo,
							change: res?.total_pipeline?.ms_cpo_comp || 0,
						},
						options: {
							label: 'Marketing Sourced',
							color: '#133463',
						},
					},
				],
			},
		};
	}

	switch (action.type) {
		case SET_CMO_REQUEST_DATA:
			requestData = action.data;
			return { ...requestData };

		case SET_CMO_CLOSED_WON_DATA:
			state['cmo_closed_won']['roi_cards_data'] = getClosedWonData(
				action?.data?.closed_won
			);
			state['cmo_closed_won']['cards_data'] = getCardsData(
				action?.data?.closed_won
			);
			state['average_sale_cycle_data'] = getAverageSaleCycleData(action?.data);
			state['average_deal_cycle_data'] = getAverageDealCycleData(action?.data);
			state['cmo_closed_won']['closed_won_card_data'] = getClosedWonCardData(
				action?.data
			);
			return { ...state };

		case SET_CMO_PIPELINE_API_DATA:
			state['cmo_pipeline_data']['roi_cards_data'] = getCmoPipelineData(
				action.data
			);
			state['cmo_pipeline_data']['donut_data'] = getCmoPipleineDonutData(
				action.data
			);
			state['cmo_pipeline_data']['box_data'] = getCmoPipelineBoxData(
				action.data
			);
			return { ...state };

		case SET_CMO_INSIGHTS_TABLE_DATA:
			state['cmo_insights_table_data'] = action.data;
			return { ...state };

		case SET_CMO_GRAPH_DATA:
			state['cmo_graph_data'] = getCmoGraphData(
				action.data.response,
				action.data.startDate,
				action.data.endDate
			);
			return { ...state };
		case SET_CMO_REQUEST_BODY:
			switch (action?.type) {
				case 'date_range':
					state['cmo_request_body']['date_range'] = action.data;
					return { ...state };
				default:
					return { ...state };
			}
			return { ...state };
		case SET_CMO_BOOKMARK_REQUEST_BODY_DATA:
			state['cmo_bookmark_request_body'] = action.data;
			return { ...state };
		case SET_CMO_METRIC_VALUE:
			state['cmo_metric_value'] = action.data;
			return { ...state };
		case SET_CMO_DIMENSION_VALUE:
			state['cmo_dimension_value'] = action.data;
			return { ...state };
		case RESET_TO_INTIAL_STATE:
			state = {
				cmo_closed_won: {
					roi_cards_data: null,
					cards_data: null,
					closed_won_card_data: null,
				},
				average_sale_cycle_data: null,
				average_deal_cycle_data: null,
				cmo_pipeline_data: {
					roi_cards_data: null,
					donut_data: null,
					box_data: null,
				},
				cmo_insights_table_data: null,
				cmo_request_body: {
					date_range: InsightsData.getDefaultRange(),
					filter_data: InsightsData.getDefaultFilters(),
				},
				cmo_bookmark_request_body: null,
				// cmo_metric_value: 'spend',
				// cmo_dimension_value: 'channel',
			};
			return { ...state };
	}
};
