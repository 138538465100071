import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import './Button.scss';
import { makeStyles } from '@mui/styles';

interface IBtnLoaderProps {
	onClick?: Function;
	btnText: string;
	className?: string;
	type: string;
	color?: string;
	textColor?: string;
	variant?: string;
	disable?: boolean;
	size?: string;
	startIcon?: any;
	endIcon?: any;
	ariaControls?: any;
	ariaHaspopup?: any;
	ariaExpanded?: any;
	hidden?: boolean;
	style?: any;
	component?: any;
	href?: string;
	onKeyDown?: any;
}

const Btn: any = (props: any) => {
	const theme = useTheme();
	const useStyles = makeStyles((theme: any) => ({
		btnClass: {
			fontSize:
				`${theme?.typography?.sidebarfont?.headingFontSize} !important` ||
				'12px',
		},
	}));
	const classes = useStyles(theme);
	return props.hidden ? (
		<></>
	) : (
		<Button
			type={props.type}
			style={props.style}
			className={
				props.className
					? `${props.className} ${classes.btnClass} `
					: `defaultButton ${classes.btnClass}`
			}
			onClick={props.onClick}
			variant={props.variant || 'contained'}
			size={props.size}
			classes={{
				root: `Button__fontSize ${classes.btnClass}`,
			}}
			onKeyDown={props.onKeyDown}
			startIcon={props.startIcon || ''}
			color={props.color ? props.color : 'primary'}
			disabled={props.disable ? props.disable : false}
			endIcon={props.endIcon || ''}
			aria-controls={props.ariaControls ? 'basic-menu' : undefined}
			aria-haspopup={props.ariaHaspopup}
			aria-expanded={props.ariaExpanded ? 'true' : undefined}
		>
			{props.btnText}
		</Button>
	);
};
export default Btn;
