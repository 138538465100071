export const SET_CMO_REQUEST_DATA = 'SET_CMO_REQUEST_DATA';
export const SET_CMO_CLOSED_WON_DATA = 'SET_CMO_CLOSED_WON_DATA';
export const SET_CMO_PIPELINE_DATA = 'SET_CMO_PIPELINE_DATA';
export const SET_CMO_AVG_CARD_DATA = 'SET_CMO_AVG_CARD_DATA';
export const SET_CMO_AVG_DEAL_DATA = 'SET_CMO_AVG_DEAL_DATA';
export const SET_CMO_GRAPH_DATA = 'SET_CMO_GRAPH_DATA';
export const SET_CMO_REQUEST_BODY = 'SET_CMO_REQUEST_BODY';
export const SET_CMO_PIPELINE_API_DATA = 'SET_CMO_PIPELINE_API_DATA';
export const SET_CMO_INSIGHTS_TABLE_DATA = 'SET_CMO_INSIGHTS_TABLE_DATA';
export const SET_CMO_BOOKMARK_REQUEST_BODY_DATA =
	'SET_CMO_BOOKMARK_REQUEST_BODY_DATA';
export const SET_CMO_DIMENSION_VALUE = 'SET_CMO_DIMENSION_VALUE';
export const SET_CMO_METRIC_VALUE = 'SET_CMO_METRIC_VALUE';
export const RESET_TO_INTIAL_STATE = 'RESET_TO_INTIAL_STATE';
