import { useTheme } from '@mui/styles';
import * as React from 'react';
import DiggLogo from '../../assets/Logo/DigGrowthLogo.svg';
import DiggLogoDark from '../../assets/Logo/DiggLogoDark.svg';
import useLocalStorage from '../../utils/localStorage';
import { Grid, Typography } from '@mui/material';
import {
	InsightsBreadCrumbs,
	InsightsData,
} from '../Reports/Dashboard/InsightsData';
import ReactDateTimePicker from '../../components/DateTimePicker/ReactDateTimePicker';
import moment from 'moment';
import { CalendarTodayOutlined } from '@mui/icons-material';

const PageTemplate = (props: any) => {
	const theme: any = useLocalStorage.getItem('appTheme');
	const location: any = window.location.pathname;
	const dates: any = useLocalStorage.getItem('dateRange');
	const checkLogo = useLocalStorage.getItem('logo');
	let [dateRange, setDateRange]: any = React.useState(
		dates ? dates : InsightsData.getDefaultRange()
	);
	let reportHeader: any =
		document.getElementsByClassName('commonPageHeading')[0];
	let cmoDashboard: any = document.getElementsByClassName('dashboard')[0];
	if (reportHeader) {
		reportHeader.style.marginTop = '2.2rem';
	}
	if (cmoDashboard) {
		cmoDashboard.style.marginTop = '3rem';
	}
	const Data = useLocalStorage.getItem('ShareReports_data');
	const DBLogo: any = Data?.DBLogo;
	const LBLogo: any = Data?.LBLogo;
	const isClient = useLocalStorage.getItem('isClient');
	return (
		<div>
			{/* ------------------------------header for cmo dashboard */}
			{location == '/insights/cmo/dashboard' && (
				<div
					style={{
						position: 'absolute',
						top: '2.1rem',
						left: '0px',
						width: '98%',
						padding: '20px',
						backgroundColor: theme == 'light' ? 'white' : '#171725',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div
						style={{ fontSize: theme?.typography?.funnelText, width: '35em' }}
					>
						<Typography
							variant='h5'
							component='span'
							color={theme == 'dark' ? 'white' : 'black'}
						>
							CMO Dashboard
						</Typography>
						<div
							style={{
								fontSize: theme?.typography?.sidebarfont?.headingFontSize,
								color: theme?.palette?.text?.tertiary,
								margin: '5px 0px',
								fontStyle: 'italic',
							}}
						>
							{`CMO > CMO Dashboard`}
						</div>
					</div>

					<div
						style={{
							float: 'right',
							display: 'flex',
							alignSelf: 'center',
							justifyContent: 'right',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<div
							style={{
								// height:'30px',
								width: '270px',
								padding: '20px',
								borderRadius: '40px',
								border:
									theme == 'dark' ? '1px solid #9149ff' : '1px solid white',
								display: 'flex',
								fontSize: theme?.typography?.globalAttributes,
								flexDirection: 'row',
								alignItems: 'center',
								gap: '10px',
							}}
						>
							<CalendarTodayOutlined
								style={{ fill: theme.palette.text.tertiary }}
							/>
							<div>
								{moment(dateRange.start_date).format('MMM DD , YYYY')} -{' '}
								{moment(dateRange.end_date).format('MMM DD , YYYY')}
							</div>
						</div>
					</div>
				</div>
			)}
			{/* --------------------------header -------------------------------- */}
			{props.pageNum == 1 ? (
				<div
					style={{
						position: 'absolute',
						top: '0px',
						left: '0px',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						backgroundColor:
							theme == 'light' ? 'rgb(255, 255, 255)' : '#171725',
					}}
				>
					{isClient ? (
						<img
							crossOrigin=''
							src={theme == 'light' ? LBLogo : DBLogo}
							alt='Company Logo'
							style={{ height: '30px', margin: '5px 0px 0px 10px' }}
						/>
					) : (
						<img
							src={theme == 'light' ? DiggLogo : DiggLogoDark}
							alt='Company Logo'
							style={{ height: '30px', margin: '5px 0px 0px 10px' }}
						/>
					)}
					<img
						src={checkLogo}
						alt='Client Logo'
						style={{
							height: '30px',
							margin: '5px 10px 0px 0px',
							backgroundSize: 'contain',
							backgroundPosition: 'center',
						}}
					/>
				</div>
			) : (
				''
			)}
			{/* --------------------------footer------------------------------- */}
			{/* <div
				style={{
					position: 'absolute',
					top: '96.8%',
					left: '0px',
					width: '98%',
					padding: '20px',
					backgroundColor: theme == 'light' ? 'white' : '#24282F',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div style={{ fontSize: '24px' }}>
					{' '}
					Page {props.pageNum} of {props.totalPages}
				</div>
				<img
					src={theme == 'light' ? DiggLogo : DiggLogoDark}
					alt='Company Logo'
					style={{ height: '30px', width: '250px' }}
				/>
			</div> */}
			// ----------------------code for showing watermark on each
			page-----------------------------------
			{/* <div style={{ position: "absolute", top: "50%", left: "30%" , width:'100%'}}>

		                       <div className='about_big'>Diggrowth</div>
		 </div>  */}
		</div>
	);
};

export default PageTemplate;
